import React, { useEffect, useState } from "react";
import ReportOptions from "../ReportOptions";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { proxy } from '../../process/api';
import DynamicDTFloatTabs from "../DynamicDTFloatTabs";
import DropDownControl from "../DropDownControl";
import BrandedLoader from "../BrandedLoader";
import MultiSelectOptions from "./../MultiSelectOptions";
import ProcessLoader from "../ProcessLoader";
import MultiSheetProcessingLoader from "../MultiSheetProcessingLoader";
import { hst03_report_json_format, generateExcel, getHeaderColNames, excelColorCodes, transformNavDataForLabels,   goFullScreen } from "../../utils";


const BI06 = () => {

    const navigate = useNavigate();
    const div_data = JSON.parse(localStorage.getItem('div_data'));
    const year_data = JSON.parse(localStorage.getItem('year_data'));
    const month_data = JSON.parse(localStorage.getItem('month_data'));
    const depo_data = JSON.parse(localStorage.getItem('depo_data'));
    const allIndiaTypeDepo = depo_data.data.filter(item => item.value !== "NA"); //removed 0 from all type users
    const [sheetloaderMessage, setSheetLoaderMessage] = useState("");

    const [showOptions, setShowOptions] = useState(true);
    const [tabLoader, setTabLoader] = useState([]); //add product codes
    const [reportGenData, setreportGenData] = useState({});
    const [SelectedDiv, setSelectedDIV] = useState(div_data.data[0].value);
    const [SelectedYear, setSelectedYear] = useState(year_data.data[0].value);
    const [SelectedDepo, setSelectedDepo] = useState(allIndiaTypeDepo[0].value);
    const [SelectedStartMonth, setSelectedStartMonth] = useState(month_data.data[0].value);
    const [SelectedEndMonth, setSelectedEndMonth] = useState(month_data.data[month_data.index - 1].value);
    const [productListItems, setProductListItems] = useState([]);
    const [productlistLoading, setProductlistLoading] = useState(true);
    const [SelectedProductCodes, setSelectedProductCodes] = useState([])
    const [productReportData, setProductReportdata] = useState([]);
    const [ReportType, setReportType] = useState(4);
    const [showProcessLoader, setShowProcessLoader] = useState(false);
    const [reportGenerationCompleted, setReportGenerationCompleted] = useState(false);
    const [reportDataReady, setReportDataReady] = useState(false);
    const [MultiSheetDownloadStart, setMultiSheetDownloadStart] = useState(false);
    const [pageTitle, setPageTitle] = useState('');


    useEffect(() => {

        if (reportGenerationCompleted) {
            setReportDataReady(true);
        }
    }, [reportGenerationCompleted]);


    useEffect(() => {
        if (reportDataReady) {
            var multiReportTitle = [];

            var reportJson = {};
            reportJson.fileName = "Selective Product - HQ/Region/Area/Branch-" + SelectedYear + ".xlsx";
            reportJson.title = "Selective Product - HQ/Region/Area/Branch";
            reportJson.author = "Aristo Pharma";


            reportJson.sheets = [];
            productReportData.map((singlereport, index) => {


                //
                reportJson.sheets.push({
                    name: SelectedProductCodes[index].value.toString().trim(),
                    data: []
                })
                multiReportTitle.push(singlereport.data.title);

                if (singlereport.data.data.length > 0) {

                    const columns = Object.keys(singlereport.data.data[0]).filter(key => key !== 'color');
                    const headerFormatted = columns.map(key => getHeaderColNames(key));



                    reportJson.sheets[index].data.push({
                        backgroundColor: "#0043a9",
                        fontColor: "#FFFFFF",
                        fontSize: 13,
                        bold: true,
                        values: headerFormatted
                    });

                    const backgroundColors = excelColorCodes;

                    singlereport.data.data.forEach(item => {
                        const row = {
                            backgroundColor: backgroundColors[item.color] || "",
                            fontColor: item.color === 2 ? "#FFFFFF" : "#333333",
                            fontSize: 12,
                            bold: false,
                            values: columns.map(col => item[col])
                        };
                        reportJson.sheets[index].data.push(row);
                    });

                } else {

                    const columns = ['NoData'];
                    const item = { "NoData": "No Data" }
                    const headerFormatted = ["No Data"];
                    reportJson.sheets[index].data.push({
                        backgroundColor: "#0043a9",
                        fontColor: "#FFFFFF",
                        fontSize: 13,
                        bold: true,
                        values: headerFormatted
                    });
                    const row = {
                        backgroundColor: "",
                        fontColor: "#333333",
                        fontSize: 12,
                        bold: false,
                        values: columns.map(col => item[col])
                    };
                    reportJson.sheets[index].data.push(row);

                }
            });

            generateExcel(reportJson, 40, 20, 0, 3, multiReportTitle).catch(console.error);

            setReportDataReady(false);
            setMultiSheetDownloadStart(false);
            setReportGenerationCompleted(false);
        }
    }, [reportDataReady]);


    const handleExportMultiSheet = async () => {

        setMultiSheetDownloadStart(true);


        if (SelectedProductCodes.length !== productReportData.length) {
            for (let i = 0; i < SelectedProductCodes.length; i++) {
                setSheetLoaderMessage("Processing data for product #" + SelectedProductCodes[i].value);
                await generateProductReport(SelectedProductCodes[i].value);
            }
            setReportGenerationCompleted(true);
        } else {
            setReportGenerationCompleted(true);
        }


    }




    const handleTabChange = (productCode) => {
        if (!tabLoader.includes(productCode)) {
            generateProductReport(productCode);
        } else {
        }
    }

    const generateProductReport = async (productCode) => {

        const payload = {
            "myear": parseInt(SelectedYear),
            "div_code": parseInt(SelectedDiv),
            "depo_code": parseInt(SelectedDepo),
            "smon": parseInt(SelectedStartMonth),
            "emon": parseInt(SelectedEndMonth),
            "code": parseInt(productCode),
            "rep_type": parseInt(ReportType)
        }

        var reportAPIResponse = {};
        reportAPIResponse = await proxy(`/api/mis/branchmisrepo6`, payload, "get");
        var processedReportData = [];
        if (reportAPIResponse == '') {
            processedReportData = { title: "NO DATA", data: [] };
        } else {
            processedReportData = reportAPIResponse;
        }

        const newProductReport = { "product_code": productCode, "data": processedReportData };
        setProductReportdata((productReportData) => {
            const index = productReportData.findIndex(p => p.product_code === newProductReport.product_code);
            if (index > -1) {
                return productReportData.map((p, i) => i === index ? newProductReport : p);
            } else {
                // Product doesn't exist, add it
                return [...productReportData, newProductReport];
            }
        });

        setTabLoader([...tabLoader, productCode]);
    }


    function convertProductData(dataAPIResponse) {
        if (dataAPIResponse && Array.isArray(dataAPIResponse.data)) {
            const converted_list = dataAPIResponse.data.map(item => ({
                value: item['value'],
                label: item['name']
            }));
            return converted_list;
        }
        return [];
    }

    const handleProductChange = (selectedProducts) => {
        if (selectedProducts.length > 5) {
            alert('Maximum of 5 products can be selected at a time. Please remove extra products.');
        } else {
            setSelectedProductCodes(selectedProducts);
        }
    }

    const loadProductItems = async (division, year) => {
        setProductlistLoading(true);
        const payload = {};
        const dataAPIResponse = await proxy(`/api/mis/productlist/${division}/${year}/0`, payload, "get", true);
        const converted_list = convertProductData(dataAPIResponse);
        setProductListItems(converted_list);
        setProductlistLoading(false);

    }


    function getTabreport(pcode) {
        const index = productReportData.findIndex(item => item.product_code === pcode);
        return productReportData[index].data;

    }
    const handleReportGen = () => {
        goFullScreen();
        
        setShowProcessLoader(true);
        document.getElementById('btnReportGen').innerHTML = "PROCESSING...";
        if (SelectedProductCodes.length == 0) {
            alert('Select aleast one product');
            return;
        }
        generateProductReport(SelectedProductCodes[0].value);
        setShowOptions(false);
        setShowProcessLoader(false);
    }

    const handleBranchChange = async (newValue) => {
        setSelectedDepo(newValue);
    }


    useEffect(() => {
        transformNavDataForLabels("BI06").then(title => {
            setPageTitle(title);
        });
        loadProductItems(SelectedDiv, SelectedYear);
    }, []);





    return (<>
        <div className="main-content">
            {showProcessLoader && <ProcessLoader />}
            {showOptions ?
                 <ReportOptions title={pageTitle} >
                    <div className="report-option-wrapper">
                        <div className="report-option-row">
                            <div className="report-option-item">
                                <label>Division</label>
                                <DropDownControl data={div_data.data} id="report_div_list" name="report_div_list" onChange={(newVal) => { setSelectedDIV(newVal); loadProductItems(newVal, SelectedYear); }} />
                            </div>

                            <div className="report-option-item ml-20">
                                <label className="mr-16">Branch</label>
                                <DropDownControl data={allIndiaTypeDepo} id="report_branch_list" name="report_branch_list"

                                    onChange={handleBranchChange}
                                />
                            </div>

                        </div>
                        <div className="report-option-row">
                            <div className="report-option-item ">
                                <label>Mkt&nbsp;Year</label>
                                <DropDownControl data={year_data.data} id="report_mktyear_list" name="report_mktyear_list" onChange={(newYear) => { console.log(newYear); setSelectedYear(newYear); loadProductItems(SelectedDiv, newYear); }} />

                            </div>

                            <div className="report-option-item ml-20">
                                <label>From</label>
                                <DropDownControl data={month_data.data} id="report_startyear_list" name="report_startyear_list" selectedValue={SelectedStartMonth} onChange={(val) => { console.log(val); setSelectedStartMonth(val) }} />
                            </div>
                            <div className="report-option-item ml-10">
                                <label>To</label>
                                <DropDownControl data={month_data.data} id="report_endyear_list" name="report_endyear_list" selectedValue={SelectedEndMonth} onChange={(val) => { console.log(val); setSelectedEndMonth(val) }} />
                            </div>

                        </div>
                        <div className="report-option-row" style={{display:"none"}}>
                            <div className="report-option-item">
                                <input type="radio" class="js-switched mr-10" id="report_type_1" name="report_type" checked={(true && ReportType === 1)}
                                    onChange={(e) => { setReportType(1);; }} />
                                <label htmlFor="report_type_1">H.Q.</label>
                            </div>
                            <div className="report-option-item ml-14" style={{display:"none"}}>
                                <input type="radio" class="js-switched mr-10" id="report_type_2" name="report_type" checked={(true && ReportType === 2)}
                                    onChange={(e) => { setReportType(2); }} />
                                <label htmlFor="report_type_2">Region</label>
                            </div>

                            <div className="report-option-item ml-14" style={{display:"none"}}>
                                <input type="radio" class="js-switched mr-10" id="report_type_3" name="report_type" checked={(true && ReportType === 3)}
                                    onChange={(e) => { setReportType(3); }} />
                                <label htmlFor="report_type_3">Area</label>
                            </div>
                            <div className="report-option-item ml-14">
                                <input type="radio" class="js-switched mr-10" id="report_type_4" name="report_type" checked={(true && ReportType === 4)}
                                    onChange={(e) => { setReportType(4); }} />
                                <label htmlFor="report_type_4">Branch</label>
                            </div>
                        </div>


                        <div className="report-option-row">
                            <div className="report-option-item">
                                <label >Select&nbsp;Product</label>
                                {productlistLoading ? <BrandedLoader /> :
                                    <MultiSelectOptions
                                        itemsData={productListItems}
                                        onChange={handleProductChange}
                                    />}

                            </div>
                        </div>
                        <div className="report-option-action-row">
                            <button onClick={(e) => { navigate('/dashboard'); }} className="btn btn-outline btn-md btn-sec mr-10 fw-500">BACK</button>
                            <button onClick={handleReportGen} className="btn btn-md btn-primary pr-30 pl-30 fw-500 " id="btnReportGen" >SUBMIT</button>
                        </div>
                    </div>
                </ReportOptions>
                :
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="card report-card" style={{ width: "auto" }}>
                                <div className="card-title report-card-title">{'Selective Product - HQ/Region/Area/Branch'}
                                    <button className="btn btn-outline btn-md  mr-10 fw-500" onClick={(e) => { window.location.reload() }}>BACK</button>
                                </div>
                                <div className="card-body card-body-multireport">


                                    <ul class="nav nav-tabs nav-tabs-light-mode nav-justified" role="tablist">

                                        {SelectedProductCodes.map((product, index) => (

                                            <li key={product.value} class="nav-item ">
                                                <a class={`nav-link ${index == 0 && 'active'}`} onClick={() => { handleTabChange(product.value) }} data-toggle="tab" href={`#tab${product.value}`} role="tab">{product.label}</a>
                                            </li>

                                        ))}

                                    </ul>

                                    <div class="tab-content">
                                        {SelectedProductCodes.map((product, index) => (

                                            <div key={product.value} className={`tab-pane fade ${index == 0 && 'active show'}`} id={`tab${product.value}`}>

                                                {
                                                    tabLoader.includes(product.value) ?

                                                        <>
                                                            <h3 className="fs-16 fw-500">{getTabreport(product.value).title}</h3>

                                                            {getTabreport(product.value).data.length > 0 ? <DynamicDTFloatTabs
                                                                tableID={`tabledt_${product.value}`}
                                                                tableData={getTabreport(product.value)}
                                                                firstColWidth="220px"
                                                                fixedColumns={true}
                                                                secColWidth="auto"
                                                                handleExportSheet={handleExportMultiSheet}
                                                            /> : <p className="alert alert-secondary" role="alert">No data for this product.</p>}

                                                        </>
                                                        : <BrandedLoader />
                                                }


                                            </div>
                                        ))}


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </>
            }

            {MultiSheetDownloadStart && <MultiSheetProcessingLoader title="Please wait while we download the excel file." loadingText={sheetloaderMessage} />}

        </div>

    </>);
}

export default BI06;