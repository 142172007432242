// Import React and Chart.js components
import React, { useEffect, useRef, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { proxy } from './../process/api';

const DashChart = () => {

  const [chartHeight, setChartHeight] = useState('200px');
  const [chartLabels, setChartLabels] = useState(["OCT", "NOV", "DEC", "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP"]);
  const [chartData, setChartData] = useState([]);
  const [isChartLoading, setChartLoading] = useState(true);

  const getChartData = async () => {

    const selected_year = localStorage.getItem('selected_year');
    const selected_month = localStorage.getItem('selected_month');
    const selected_div = localStorage.getItem('selected_div');
    const selected_depo = localStorage.getItem('selected_depo');

    const apiResponse = await proxy(`/api/mis/dashboardchart/${selected_year}/${selected_div}/${selected_depo}`, {}, "get", true);
    if (apiResponse.chart_type) {
      setChartLoading(false);
      setChartLabels(apiResponse.chart_labels)
      const temp_dataset = apiResponse.data_set;

      var chartArrayProcessed = [];
      temp_dataset.map((singleData, index) => {
        chartArrayProcessed.push({
          label: String(singleData.dataset_label).toUpperCase(),
          data: singleData.values,
          borderColor: singleData.data_set_color,
          backgroundColor: singleData.data_set_color,
          borderWidth: 0,
          borderRadius: 0,
          borderSkipped: false,
        });
      });

      setChartData(chartArrayProcessed);
      setChartLoading(false);
    }


  }
  useEffect(() => {

    const mainContentElement = document.querySelector('.main-content');
    if (mainContentElement) {
      const mainContentHeight = mainContentElement.offsetHeight - 10;
      const newChartHeight = Math.max(mainContentHeight - 380, 0);
      setChartHeight(`${newChartHeight}px`);
    }

    getChartData();

  }, []);


  const data = {
    maintainAspectRatio: false, // Add this line
    responsive: true,
    labels: chartLabels,
    datasets: chartData
  };

  const options = {
    plugins: { tooltip: {backgroundColor: "rgba(188, 211, 232, 0.9)", titleColor: "rgba(0,0,0,1)", bodyColor: "rgb(30,30,30)" } },
    layout: {

      padding: 0
    },
    scales: {

      y: {
        beginAtZero: true,
      },
    },
  };

  return (

    <>
      {isChartLoading ?
        <div >
          <BeatLoader color="#1940a9" />
        </div>
        :
        <div style={{ height: chartHeight, padding: `20px` }}>
          <Bar data={data} options={options} width={800} height={260} />
        </div>
      }

    </>

  );
};

export default DashChart;
