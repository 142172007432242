import React, { useEffect, useState } from "react";
import Select from 'react-select'

const MultiSelectOptions = ({ itemsData, onChange, defValue="" }) => {

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '220px', 
    }),
  };

  return (<>
    <Select
      defaultValue={defValue}
      isMulti={true}
      closeMenuOnSelect={false}
      options={itemsData}
      styles={customStyles}
      onChange={onChange}
    />
  </>);
}

export default MultiSelectOptions;