import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Preview from "./Preview";
import Footer from './Footer';
import Topbar from './Topbar';
import { Outlet } from 'react-router-dom';



const Layout = () => {

    
    return (
        <>
            <Header />
            <Topbar />
            <main class="main-container">
                <Outlet /> {/* This renders the child routes */}
                <Preview/>
            <Footer />
         
            </main>
           
        </>
    );
};

export default Layout;
