const Footer = () => {
    return (
       <footer class="site-footer">
        <div class="row">
          <div class="col-md-6">
            <p class="text-center text-md-left">Copyright © 2024. </p>
          </div>

          <div class="col-md-6">
            <ul class="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
              <li class="nav-item">
                <a class="nav-link" href="#">INNOVATION BY PROMPT SOFTWARE CONSULTANTS</a>
              </li>
              
            </ul>
          </div>
        </div>
      </footer>
    );
};

export default Footer;
