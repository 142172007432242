import React from 'react';

const DropDownControl = ({ id, name, data, selectedValue, onChange }) => {
  const defaultValue = selectedValue || (data.length > 0 ? data[0].value : '');

  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

 
  return (
    <select  name={name} id={id} defaultValue={defaultValue} className='form-control' onChange={handleChange} tabIndex={0}>
      {data.map((item) => (
        <option key={item.value} value={item.value}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

export default DropDownControl;
