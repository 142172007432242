import React, {useEffect, useState} from "react";
import ReportOptions from "../ReportOptions";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";
import {proxy} from '../../process/api'; 
import DynamicDTFloat from "../DynamicDTFloat";
import DropDownControl from "../DropDownControl";
import { BeatLoader } from "react-spinners";
import {nw01_report_json_format, generateExcel,getHeaderColNames, excelColorCodes,transformNavDataForLabels} from "../../utils";

import ProcessLoader from "../ProcessLoader";


const NW01 = () =>{

    const navigate = useNavigate();

    const div_data = JSON.parse( localStorage.getItem('div_data'));
    const year_data = JSON.parse( localStorage.getItem('year_data'));
    const month_data = JSON.parse( localStorage.getItem('month_data'));
    const depo_data = JSON.parse( localStorage.getItem('depo_data'));

    const [showOptions, setShowOptions] = useState(true);
    const [reportGenData, setreportGenData] = useState({});
    const [SelectedDiv, setSelectedDIV] = useState(div_data.data[0].value);
    const [SelectedYear, setSelectedYear] = useState(year_data.data[0].value);
    const [SelectedDepo, setSelectedDepo] = useState(depo_data.data[0].value);
    const [SelectedStartMonth, setSelectedStartMonth] = useState(month_data.data[0].value);
    const [SelectedEndMonth, setSelectedEndMonth] = useState(month_data.data[month_data.index-1].value);
    const [UnitValue, setUnitvalue] = useState(1);
    const [ReportType, setReportType] = useState(1);
    const [ReportOption, setReportOption] = useState(1);
    const [showProcessLoader, setShowProcessLoader] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
    const reportOptionList = [
        {"name":"Gross Sale", "value": 1},
        {"name":"Saleable", "value": 2},
        {"name":"Expiry", "value": 3},
        {"name":"Breakage", "value": 4},
        {"name":"Net Sale", "value": 5},
        {"name":"Budget", "value": 6},
        {"name":"Lys Sales", "value": 7},
        {"name":"Ach.", "value": 8},
        {"name":"Gth.", "value": 9},
        {"name":"PMR", "value": 10},
        {"name":"PI Sale", "value": 11}
    ]

    const handleExportSheet = async () =>{

        var reportJson = {};
        reportJson.fileName = reportGenData.title+".xlsx";
        reportJson.title = reportGenData.title;
        reportJson.author = "Aristo Pharma";

        reportJson.sheets = [
            {
                name: "Sheet 1",
                data: []
            }
        ]
        
    const columns = Object.keys(reportGenData.data[0]).filter(key => key !== 'color');
    const headerFormatted = columns.map(key => getHeaderColNames(key));
    
    reportJson.sheets[0].data.push({
        backgroundColor: "#0043a9", 
        fontColor: "#FFFFFF",
        fontSize: 13,
        bold: true,
        values: headerFormatted
    });

    const backgroundColors = excelColorCodes;

    reportGenData.data.forEach(item => {
        const row = {
            backgroundColor: backgroundColors[item.color] || "", 
            fontColor: item.color === 2 ? "#FFFFFF" : "#333333",
            fontSize: 12,
            bold: false,
            values: columns.map(col => item[col])
        };
        reportJson.sheets[0].data.push(row);
    });
          
        generateExcel(reportJson).catch(console.error);

    }

    const handleReportGen = async() => {
     
        setShowProcessLoader(true);
        document.getElementById('btnReportGen').innerHTML = "PROCESSING...";  
        const payload = {
            "myear": parseInt( SelectedYear),
            "div_code": parseInt(SelectedDiv),
            "depo_code": parseInt(SelectedDepo),
            "smon": parseInt(SelectedStartMonth),
            "emon": parseInt(SelectedEndMonth),
            "rep_type":parseInt( ReportType), //product wises or group wise
            "uv": parseInt(UnitValue),
            "opt_type": parseInt( ReportOption) // Report Option
        }

       
       
        var reportAPIResponse = {};
       if( ReportOption == 9 || ReportOption == 8){

         reportAPIResponse  = await proxy(`/api/mis/mktrepo1Ach`,payload, "get");
       }else if(ReportOption == 10){
        reportAPIResponse  = await proxy(`/api/mis/mktrepo1Pmr`,payload, "get");
       }else{
         reportAPIResponse  = await proxy(`/api/mis/mktrepo1`,payload, "get");
       }
       
       const new_data = nw01_report_json_format(reportAPIResponse);
       setreportGenData(new_data);
       setShowProcessLoader(false);
        setShowOptions(false);
    }

    const handleBranchChange = async (newValue)=>{
        setSelectedDepo(newValue);
       
        
    }

    useEffect(  ()=>{
        transformNavDataForLabels("NW01").then(title => {
            setPageTitle(title); 
        });
       

    },[]);
   
    return(
        <>
        <div class="main-content">
        {showProcessLoader && <ProcessLoader /> }
        {showOptions?
         <ReportOptions title={pageTitle} >
            <div className="report-option-wrapper">
                <div className="report-option-row">  
                <div className="report-option-item">
                    <label>Division</label>
                    <DropDownControl data={div_data.data} id="report_div_list" name="report_div_list" onChange={(newVal)=>{ setSelectedDIV(newVal);}}   />
                </div>

                <div className="report-option-item ml-20">
                        <label>Branch</label>
                        <DropDownControl data={depo_data.data} id="report_branch_list" name="report_branch_list"

                            onChange={handleBranchChange}
                        />
                    </div>

                </div>

                <div className="report-option-row">  
                <div className="report-option-item">
                <label>From</label>
                    <DropDownControl data={month_data.data} id="report_startyear_list" name="report_startyear_list"  selectedValue={SelectedStartMonth} onChange={(val)=>{console.log(val);setSelectedStartMonth(val)}} />
</div>
                    <div className="report-option-item ml-10">
                    <label>To</label>
                    <DropDownControl data={month_data.data} id="report_endyear_list" name="report_endyear_list" selectedValue={SelectedEndMonth}   onChange={(val)=>{console.log(val);setSelectedEndMonth(val)}}  />
                    </div>
                    <div className="report-option-item ml-10">
                    <label>Mkt&nbsp;Year</label>
                    <DropDownControl data={year_data.data} id="report_mktyear_list" name="report_mktyear_list"  onChange={(newYear)=>{console.log(newYear);setSelectedYear(newYear)}}  />
            
                </div>
                </div>
                <div className="report-option-row">
                    <div className="report-option-item">
                    <input type="radio" class="js-switched mr-10" name="report_branchhq"  checked={(true && ReportType ===1)}  
                    onChange={(e)=>{setReportType(1);setUnitvalue(1);}} />
                        <label>Productwise</label>
                    </div> 
                    <div className="report-option-item ml-10">
                    <input type="radio" class="js-switched mr-10" name="report_branchhq"  checked={(true && ReportType ===2)}  
                    onChange={(e)=>{setReportType(2);setUnitvalue(2);}}/>
                        <label>Groupwise</label>
                    </div>
                </div>
                
                <div className="report-option-row">
                    <div className="report-option-item">
                      
                        <input type="radio" class="js-switched mr-10" disabled={(ReportType==2 && true)} name="report_unitvalue" checked={(true && UnitValue ===1)} onChange={(e)=>{setUnitvalue(1);}}  />

                        <label>Unit</label>
                        
                    </div>
                    <div className="report-option-item ml-10">
                    <input type="radio" class="js-switched mr-10" name="report_unitvalue" checked={(true && UnitValue ===2)}  onChange={(e)=>{setUnitvalue(2);}}/>
                        <label>Value</label>
                    </div>
                </div>
               

                <div className="report-option-row">
                <div className="report-option-item">
                        <label>Report&nbsp;Option</label>
                        <DropDownControl 
                        data={reportOptionList}
                         id="report_branch_list" 
                         name="report_branch_list"
                           selectedValue={ReportOption}
                           onChange={(optionValue)=>{setReportOption(optionValue)}}
                        />
                        </div>

                </div>
               


                <div className="report-option-action-row">
                    <button onClick={(e)=>{navigate('/dashboard');}} className="btn btn-outline btn-md btn-sec mr-10 fw-500">BACK</button>
                    <button onClick={handleReportGen} className="btn btn-md btn-primary pr-30 pl-30 fw-500 " id="btnReportGen" >SUBMIT</button>
                </div>
            </div>
        </ReportOptions>    
        :
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card report-card" style={{width:"auto"}}>
                        <div className="card-title report-card-title">{reportGenData.title}
                            <button className="btn btn-outline btn-md  mr-10 fw-500" onClick={(e)=>{ window.location.reload()}}>BACK</button>
                        </div>
                        <div className="card-body">
                            <DynamicDTFloat 
                                tableData={reportGenData}
                                handleExportSheet={handleExportSheet}
                                fixedColumns={true}
                            />    

                        </div>
                        <div className="card-footer">

<button className="btn btn-outline btn-primary text-white btn-sm float-right" 
    onClick={handleExportSheet}
>Export Excel</button>
</div>
                    </div>
                </div>
            </div>
        </>
    }
        
        
        </div>
        </>
        
    );
}

export default NW01;


