import React, { useEffect, useRef, useState } from 'react';
import Aside from './Aside';
import {proxy} from './../process/api';

const Header = () => {


    const [navdata, setNavdata] = useState({"data":[]});
    const getNavdata = async()=>{
        const loginMenuApiResponse  = await proxy(`/api/mis/loginmenu`,{}, "get", true);
        setNavdata(loginMenuApiResponse);
    }

    useEffect(()=>{
        getNavdata();
    },[]);
  
   return (
         <aside class="sidebar sidebar-expand-md ">

            <header class="sidebar-header">
               <a href="/"> 
                  <img src="../../images/logo.png" />
               </a>

            </header>
         
         <Aside menuData={navdata.data} />
         </aside>



   );
};

export default Header;
