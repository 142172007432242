import React from "react";
import { ClockLoader } from "react-spinners";


const ButtonLoader = ({isVisible = true}) =>{

return(
    <ClockLoader color="#ffffffcc" size={12} speedMultiplier={2} />
);

}

export default ButtonLoader;