import { useState, React, useEffect } from 'react';
import config from './../config.json';
import { useAuth } from './../AuthContext';

const Aside = ({ menuData }) => {

  const { setPreview } = useAuth();
  const nav_map = config.navigation_map;
  const icon_map = config.nav_icons;
  const [visitedLinks, setVisitedLinks] = useState([]);
  const handleMouseEnter = (item) => {

    setPreview(item);
  };


  const handleMouseLeave = () => {
    setPreview('');
  };

  const handleLinkClick = (repoName, link) => {
    setVisitedLinks(prevLinks => {
      const newLink = { name: repoName, url: link };
      const filteredLinks = prevLinks.filter(l => l.url !== link);
      const newLinks = [newLink, ...filteredLinks];
      if (newLinks.length > 10) {
        newLinks.pop();
      }

      localStorage.setItem('visitedLinks', JSON.stringify(newLinks));
      return newLinks;
    });
  };

  useEffect(() => {
    const storedLinks = JSON.parse(localStorage.getItem('visitedLinks'));
    if (storedLinks) {
      setVisitedLinks(storedLinks);
    }
  }, []);



  const getIcon = (key) => {
    return icon_map[key] ? icon_map[key] : "icon ti-layout";
  }
  return (
    <>
      <nav className="sidebar-navigation ps-container ps-theme-default ps-active-y">
        <ul className="menu">

          <li className="menu-item">
            <a className="menu-link" href="/dashboard">
              <span className="icon fa fa-home"></span>
              <span className="title">Dashboard</span>
            </a>
          </li>

          {visitedLinks.length > 0 ? <>

            <li className="menu-item">
              <a className="menu-link" href="#">
                <span className="icon ti-timer"></span>
                <span className="title">Recent Items</span>
                <span className="arrow"></span>
              </a>
              <ul className="menu-submenu">
                {visitedLinks.map((link, index) => (
                  <li className={`menu-item $`} key={index}><a className="menu-link"  href={link.url}><span className="title">{link.name}</span></a></li>
                ))}
              </ul>
            </li>

          </> : <>

            <li className="menu-item">
              <a className="menu-link" href="/recent-items">
                <span className="icon ti-timer"></span>
                <span className="title">Recent Items</span>
              </a>

            </li>

          </>}


          {menuData.length > 0 && menuData.map((tab) => (
            <li key={tab.tab_name} className="menu-item">
              <a className="menu-link" href="#">
                <span className={getIcon(tab.tab_name)}></span>
                <span className="title">{tab.tab_name}</span>
                <span className="arrow"></span>
              </a>
              {tab.menu_list && tab.menu_list.length > 0 && (
                <ul className="menu-submenu">
                  {tab.menu_list.map((item) => (
                    <li key={item.repo_id} className={`menu-item ${item.repo_id}`}  {...(nav_map[item.repo_id] ? { onMouseEnter: () => handleMouseEnter(item.repo_id), onMouseLeave: handleMouseLeave } : {})} >
                      <a onClick={() => handleLinkClick(item.repo_name, nav_map[item.repo_id])} className="menu-link" href={nav_map[item.repo_id] ? nav_map[item.repo_id] : "#"}>
                        <span className="title">{item.repo_name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>


    </>


  );
};


export default Aside;

