import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { BeatLoader} from 'react-spinners';
import config from './../config.json';
import {proxy} from './../process/api';

const DashboardLoader = () => {


    const navigate = useNavigate();


    const loadDashboardData =  async () => {

        const yearApiResponse  = await proxy("/api/mis/dashboardyearcombo",{}, "get");
        if(yearApiResponse.data){
            localStorage.setItem("year_data", JSON.stringify( yearApiResponse));   
            localStorage.setItem("selected_year", yearApiResponse.data[yearApiResponse.index].value);   
        }
      
        const divApiResponse  = await proxy("/api/mis/dashboarddivcombo",{}, "get");
        if(divApiResponse.data){
            localStorage.setItem("div_data", JSON.stringify(divApiResponse));         
            localStorage.setItem("selected_div", divApiResponse.data[divApiResponse.index].value);   
        }

        const depoApiResponse  = await proxy("/api/mis/dashboarddepocombo",{}, "get");
        if(depoApiResponse.data){
            localStorage.setItem("depo_data",JSON.stringify( depoApiResponse));      
            localStorage.setItem("selected_depo", depoApiResponse.data[depoApiResponse.index].value);      
        }

        const default_year = yearApiResponse.data[yearApiResponse.index].value;
        const default_div = divApiResponse.data[divApiResponse.index].value;
        const default_depo = depoApiResponse.data[depoApiResponse.index].value;
        const monthApiResponse  = await proxy(`/api/mis/dashboardmonthcombo/${default_year}/${default_div}/${default_depo}`,{}, "get");
       
        
         if(monthApiResponse.data){
            localStorage.setItem("month_data",JSON.stringify( monthApiResponse));    
            localStorage.setItem("selected_month", monthApiResponse.data[monthApiResponse.index-1].value);        
        }

        const loginMenuApiResponse  = await proxy(`/api/mis/loginmenu`,{}, "get", true);
        console.log(loginMenuApiResponse);

        navigate('/dashboard');

    }


    useEffect(() => {
        if (localStorage.getItem("isLoggedIn") !== 'true') {
            navigate('/login');
        }

        loadDashboardData();
    
    }, [navigate]); 


return(
    <>
         <BeatLoader color="#1940a9" />
    </>

);



}


export default DashboardLoader;