import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hasPreviewContent, setPreviewContent] = useState(''); 
 
    const login = ({token}) => {
        Object.keys(localStorage).forEach(key => {
           
            if (key.startsWith('cache-')) {
             
              localStorage.removeItem(key);
            }
          });
        localStorage.setItem("isLoggedIn", "true");
        setIsLoggedIn(true);
    };

    const logout = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("token");
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith('cache-')) {
              localStorage.removeItem(key);
            }
          });
        setIsLoggedIn(false);
    };
  
 
    const setPreview = (imageSource) =>{
      console.log(imageSource);
      setPreviewContent(imageSource);
    }

    useEffect(() => {
        
        const userLoggedIn = localStorage.getItem("isLoggedIn") === "true";
        setIsLoggedIn(userLoggedIn);
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout, hasPreviewContent, setPreview }}>
            {children}
        </AuthContext.Provider>
    );
};
