import axios from 'axios';
import config from './../config.json'; 

export const apiDoLogin = async ( endpoint, body = {}, method = 'post') => {

  var API = "";
  if(config.environment == "local"){
    API = config.localApiUrl;
  }else{
    API = config.productionApiUrl;
  }
    const url = `${API}${endpoint}`;
  
   
   try {
     const response = await axios({
       method,
       url,
       data: body,
       headers: {
         'Content-Type': 'application/json',
       },
     });
    
     return response.data;
   } catch (error) {
     console.log(error.response.data);
     return error.response.data;
    
   }
 };
 
 
 export const callMasterApi = async (endpoint, body = {}, method = 'post') => {
   const jwt = localStorage.getItem('masterToken');
   const url = `${config.masterApiUrl}${endpoint}`;
  try {
    const response = await axios({
      method,
      url,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}` 
      },
    });
    return response.data;
  } catch (error) {
    console.error('API call error:', error.response);
    throw error;
  }
};


export const callSecondaryApi = async (endpoint, body = {}, method = 'post') => {
    const jwt = localStorage.getItem('masterToken');
    const url = `${config.secondaryApiUrl}${endpoint}`;
   try {
     const response = await axios({
       method,
       url,
       data: body,
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${jwt}` 
       },
     });
     return response.data;
   } catch (error) {
     console.error('API call error:', error.response);
     throw error;
   }
 };

 const apiCache = {};

 export const proxy = async (endpoint, body = {}, method = 'get', cache = false) => {
  const jwt = localStorage.getItem('token');
  const cacheKey = `cache-${endpoint}-${JSON.stringify(body)}-${method}`;
  
  if (cache) {
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const now = new Date();
      
      if (now.getTime() - timestamp < 60 * 60 * 1000) {
        return data; 
      } else {
        localStorage.removeItem(cacheKey);
      }
    }
  }

  const payload = {
    "endpoint": endpoint,
    "method": method,
    "authBearer": jwt,
    "bodyJson": body
  };
  

  try {
    var API = "";
  if(config.environment == "local"){
    API = config.localApiUrl;
  }else{
    API = config.productionApiUrl;
  }
  
  

    const url = `${API}/api/route/do-calls/`;
    
    const response = await axios({
      method: 'post',
      url,
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}`
      },
      timeout: 120000,
    });

    if (cache) {
      const cacheEntry = {
        data: response.data,
        timestamp: new Date().getTime()
      };
      localStorage.setItem(cacheKey, JSON.stringify(cacheEntry));
    }

    return response.data;
  } catch (error) {
    console.log(error);
    console.error('API call error:', error.response);
    throw error;
  }
};
