import React, { useEffect, useState } from "react";
import ReportOptions from "../ReportOptions";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { proxy } from '../../process/api';
import DynamicDTFloat from "../DynamicDTFloat";
import DropDownControl from "../DropDownControl";
import BrandedLoader from "../BrandedLoader";
import { nw01_report_json_format, generateExcel,getHeaderColNames,excelColorCodes ,transformNavDataForLabels} from "../../utils";
import ProcessLoader from "../ProcessLoader";


const HY04 = () => {


    const navigate = useNavigate();

    const div_data = JSON.parse(localStorage.getItem('div_data'));
    const year_data = JSON.parse(localStorage.getItem('year_data'));
    const month_data = JSON.parse(localStorage.getItem('month_data'));
    const depo_data = JSON.parse(localStorage.getItem('depo_data'));
    const today = new Date();

  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();


    const [showOptions, setShowOptions] = useState(true);
    const [reportGenData, setreportGenData] = useState({});
    const [SelectedDiv, setSelectedDIV] = useState(div_data.data[0].value);
    const [SelectedYear, setSelectedYear] = useState(year_data.data[0].value);
    const [SelectedDate, setSelectedDate] = useState(`${day}/${month}/${year}`);
    const [productListItems, setProductListItems] = useState([]);
    const [productlistLoading, setProductlistLoading] = useState(true);
    const [SelectedProductCode, setSelectedProductCode] = useState(0);
    const [showProcessLoader, setShowProcessLoader] = useState(false);
    const [pageTitle, setPageTitle] = useState('');

    const handleExportSheet = async () => {

        var reportJson = {};
        reportJson.fileName = reportGenData.title + ".xlsx";
        reportJson.title = reportGenData.title;
        reportJson.author = "Aristo Pharma";
        reportJson.sheets = [
            {
                name: "Sheet 1",
                data: []
            }
        ]

        const columns = Object.keys(reportGenData.data[0]).filter(key => key !== 'color');
        const headerFormatted = columns.map(key =>getHeaderColNames( key));

        reportJson.sheets[0].data.push({
            backgroundColor: "#0043a9",
            fontColor: "#FFFFFF",
            fontSize: 13,
            bold: true,
            values: headerFormatted
        });

        const backgroundColors =excelColorCodes;

        reportGenData.data.forEach(item => {
            const row = {
                backgroundColor: backgroundColors[item.color] || "", // Default to white if undefined
                fontColor: item.color === 2 ? "#FFFFFF" : "#333333",
                fontSize: 12,
                bold: false,
                values: columns.map(col => item[col])
            };
            reportJson.sheets[0].data.push(row);
        });

        generateExcel(reportJson,20,18).catch(console.error);

    }
    const handleReportGen = async () => {

        const dateParts = document.getElementById('SelectedDate').value.split("/");
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

        const payload = {
            "mkt_year": SelectedYear,
            "div_code": SelectedDiv,
            "code": SelectedProductCode,
            "curr_date": formattedDate
        };
        console.log(payload);

        const dataAPIResponse = await proxy(`/api/mis/nearexpiry`, payload, "get");
        
        if( dataAPIResponse && dataAPIResponse.data.length > 0){
            setreportGenData(dataAPIResponse);
        setShowOptions(false);
        }else{
            alert('No data found');
        }
        
        

    }

    const loadProductItems = async (division, year) => {
        setProductlistLoading(true);
        const payload = {};
        const dataAPIResponse = await proxy(`/api/mis/productlist/${division}/${year}/0`, payload, "get", true);
        setProductListItems(dataAPIResponse);
        setSelectedProductCode(dataAPIResponse.data[0].value);
        setProductlistLoading(false);

    }

    useEffect(() => {
        loadProductItems(SelectedDiv, SelectedYear);
        transformNavDataForLabels("HY04").then(title => {
            setPageTitle(title); 
        });
    }, []);


    return (<> <div class="main-content">
        {showProcessLoader && <ProcessLoader /> }

        {


            showOptions ?
            <ReportOptions title={pageTitle} >
                    <div className="report-option-wrapper">
                        <div className="report-option-row">
                            <div className="report-option-item">
                                <label>Division</label>
                                <DropDownControl data={div_data.data} id="report_div_list" name="report_div_list"  onChange={(newVal) => { setSelectedDIV(newVal); loadProductItems(newVal, SelectedYear); }} />
                            </div>

                            <div className="report-option-item ml-20">
                                <label tabIndex={0} className="mr-16">Mkt&nbsp;Year</label>

                                <DropDownControl data={year_data.data} id="report_mktyear_list" name="report_mktyear_list" onChange={(newYear) => { console.log(newYear); setSelectedYear(newYear); loadProductItems(SelectedDiv, newYear); }} />
                            </div>
           
                        </div>
                        <div className="report-option-row">
                            <div className="report-option-item">
                                <label className="mr-16" >Product</label>
                                {productlistLoading ? <BrandedLoader /> : <DropDownControl data={productListItems.data} onChange={(newVal) => { setSelectedProductCode(newVal); }} />}
                            </div>

                        </div>
                        <div className="report-option-row">
                            <div className="report-option-item">
                                <label className="mr-16">Select&nbsp;Date</label>
                                <div class="input-group" data-provide="datepicker"  data-orientation="bottom left" data-format="dd/mm/yyyy" >
                                    <input type="text" class="form-control"  id="SelectedDate" value={SelectedDate}  />
                                    <div class="input-group-append picker">
                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="report-option-action-row">
                            <button onClick={(e) => { navigate('/dashboard'); }} className="btn btn-outline btn-md btn-sec mr-10 fw-500">BACK</button>
                            <button onClick={handleReportGen} className="btn btn-md btn-primary pr-30 pl-30 fw-500 " id="btnReportGen" >SUBMIT</button>
                        </div>
                    </div></ReportOptions>


                :
                <div className="row">
                    <div className="col-12">
                        <div className="card report-card" style={{ width: "auto" }}>
                            <div className="card-title report-card-title">{reportGenData.title}
                                <button className="btn btn-outline btn-md  mr-10 fw-500" onClick={(e) => { window.location.reload() }}>BACK</button>
                            </div>
                            <div className="card-body">
                                <DynamicDTFloat
                                    tableData={reportGenData}
                                    handleExportSheet={handleExportSheet}
                                    firstColWidth="170px"
                                    secColWidth="120px"
                                    fixedColumns={true}
                                />


                            </div>

                        </div>
                    </div>
                </div>}
    </div>
    </>);
}



export default HY04;