import React, {useEffect, useState}  from "react";
import CardDataPanel from './CardDataPanel';
import CardStatsPanel from './CardStatsPanel';
import { BeatLoader} from 'react-spinners';
import {proxy} from './../process/api';

const DashboardTopPanel = () => {

    const [isTopPanelLoading, setTopPanelLoading] = useState(true);
    const [topPanelData, setTopPanelData] = useState({});

    

    const loadTopPanel = async () =>{
    const selected_year = localStorage.getItem('selected_year');
    const selected_month = localStorage.getItem('selected_month');
    const selected_div = localStorage.getItem('selected_div');
    const selected_depo = localStorage.getItem('selected_depo');
    
        const apiResponse  = await proxy(`/api/mis/dashboardpaneldata/${selected_year}/${selected_div}/${selected_depo}/${selected_month}`,{}, "get", true);
       
        if(apiResponse.data){
          setTopPanelLoading(false);
          setTopPanelData(apiResponse.data);
        }
    }

    useEffect(()=>{
        loadTopPanel();
    },[]);

return (
    <>
            { isTopPanelLoading ?
            <BlankLoaderCard/>
            :
            <CardDataPanel 
            heading="BUDGET"
            monthly_amount={topPanelData[1].monthly}
            comm_amount={topPanelData[1].cumm}
            type="target_card_panel"
            bg_color="#3663de"
           />
            }
             { isTopPanelLoading ?
            <BlankLoaderCard/>
            :

              <CardDataPanel 
             heading="NET SALE"
             monthly_amount={topPanelData[0].monthly}
             comm_amount={topPanelData[0].cumm}
              type="sales_card_panel"
              bg_color="#5892c6"
             />
}

{ isTopPanelLoading ?
            <BlankLoaderCard/>
            :
             <CardDataPanel 
            heading={topPanelData[2].name}
            monthly_amount={topPanelData[2].monthly}
            comm_amount={topPanelData[2].cumm}
              type="last_year_card_panel"
              bg_color="#3663de"

             />}


{ isTopPanelLoading ?
            <BlankLoaderCard/>
            :
            <CardStatsPanel 
              heading="ACH%"
              monthly_amount={topPanelData[3].monthly}
              comm_amount={topPanelData[3].cumm}
              type="ach_card_panel"
              bg_color="#5892c6"
              symbol="%"

             /> 
}

{ isTopPanelLoading ?
            <BlankLoaderCard/>
            :
            <CardStatsPanel 
            heading="GTH%"
            monthly_amount={topPanelData[5].monthly}
            comm_amount={topPanelData[5].cumm}
              type="growth_card_panel"
              bg_color="#3663de"
              symbol="%"

             /> }

{ isTopPanelLoading ?
            <BlankLoaderCard/>
            :
            <CardStatsPanel 
              heading="SUR/DEF"
              monthly_amount={topPanelData[4].monthly}
              comm_amount={topPanelData[4].cumm}
              type="surdef_card_panel"
              bg_color="#5892c6"
            

             /> 
}
    </>
)
}


const BlankLoaderCard = () =>{
    return(

        <div class="col-md-2">
          
        <div className={`card card-datac`}>
              <div className={`card-content `} >
            <BeatLoader color="#1940a9" />
        </div>
        </div>
        </div>
    );
}
export default DashboardTopPanel;