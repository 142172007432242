import React, { useEffect, useState } from 'react';
import { useAuth } from './../AuthContext'; 
import HeaderDropdown from './HeaderDropdown'; 
import {getLoggedInUsername} from './../utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { proxy } from '../process/api';

const Topbar = () => {
 

   const navigate = useNavigate();
   const location = useLocation();

   var division_data = JSON.parse( localStorage.getItem('div_data'));
  var year_data     = JSON.parse( localStorage.getItem('year_data'));
  var depo_data     = JSON.parse( localStorage.getItem('depo_data'));
  var month_data    = JSON.parse( localStorage.getItem('month_data'));
 
  
   const seleted_year_value = localStorage.getItem('selected_year');
   const seleted_depo_value = localStorage.getItem('selected_depo');
   const seleted_div_value =localStorage.getItem('selected_div');
   const seleted_month_value = localStorage.getItem('selected_month');
   const [lastUpdate, setLastUpdate] = useState(["","","",""]);
   const [isFullScreen, setIsFullScreen] = useState(false);

   const { logout } = useAuth();  

   const loadLastUpdateMsg = async() =>{
      const dataAPIResponse = await proxy(`/api/mis/loginmessage/`, {}, "get", true);
     
      if( dataAPIResponse != ''){
         var msgParts =  String(dataAPIResponse.message).split("|");
         setLastUpdate(msgParts);
      }
       
   }



   const handleUpdateDashboard = () => {
      localStorage.setItem("selected_year",document.getElementById('year_list_dd').value);  
      localStorage.setItem("selected_div", document.getElementById('division_list_dd').value);   
      localStorage.setItem("selected_depo",document.getElementById('dep_list_dd').value); 
      localStorage.setItem("selected_month", document.getElementById('month_list_dd').value);   
      window.location.reload();

   }
   const handleLogoutClick = () => {
      logout(); 
    
   };

   const handleFullScreenToggle = () => {
      if (!document.fullscreenElement) {
        // Enter full-screen mode
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) { // Firefox
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari, Opera
          document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
          document.documentElement.msRequestFullscreen();
        }
        setIsFullScreen(true);
      } else {
        // Exit full-screen mode
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }
        setIsFullScreen(false);
      }
    };


   useEffect( () => {
      loadLastUpdateMsg(); 
      const handleFullScreenChange = () => {
         setIsFullScreen(!!document.fullscreenElement);
       };
   
       document.addEventListener('fullscreenchange', handleFullScreenChange);
       document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
       document.addEventListener('mozfullscreenchange', handleFullScreenChange);
       document.addEventListener('MSFullscreenChange', handleFullScreenChange);
   
       return () => {
         document.removeEventListener('fullscreenchange', handleFullScreenChange);
         document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
         document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
         document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
       };

   },[]);


   
   return (
      <header className="topbar">
         <div className="topbar-left">
            <span style={{fontWeight:'500'}}>{lastUpdate[2]} {lastUpdate[3]} HRS   {location.pathname === '/dashboard' &&  <span class="in-lacs">ALL FIGURES IN LACS</span>}</span>
         </div>

         <div className="topbar-right">
            <ul className="topbar-btns">
               <li className="dropdown">
                  <span className="topbar-btn" data-toggle="dropdown" aria-expanded="false"></span>
                  <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{ position: 'absolute', willChange: 'top, left', top: '65px', left: '-120px' }}>
                     <a className="dropdown-item" href="../page/profile.html"><i className="ti-user"></i> Profile</a>
                     <a className="dropdown-item" href="#"><i className="ti-settings"></i> Settings</a>
                     <div className="dropdown-divider"></div>
                     <a className="dropdown-item" href="../page-extra/user-login-3.html"><i className="ti-power-off"></i> Logout</a>
                  </div>
               </li>
            </ul>
            <a className='topFullScreenTrigger' title='Full Screen' onClick={handleFullScreenToggle}> <i className="material-icons">
          {isFullScreen ? 'fullscreen_exit' : 'fullscreen'}
        </i></a>
            <a className="top_switch_user" id="top_switch_user" onClick={handleLogoutClick}><i className="material-icons">exit_to_app</i></a>
            <div className="topbar-divider"></div>
            <span className="top_logged_in_username"><i className="material-icons">person</i> {getLoggedInUsername()} </span>
            <div className="topbar-divider"></div>

            {location.pathname === '/dashboard' &&
            <div className="division-selector">
               <HeaderDropdown data={depo_data.data} id="dep_list_dd" name="dep_list_dd"  selectedValue={seleted_depo_value} />
                <HeaderDropdown data={division_data.data} id="division_list_dd" name="division_list_dd" selectedValue={seleted_div_value}/>
                <HeaderDropdown data={month_data.data} id="month_list_dd" name="month_list_dd" selectedValue={seleted_month_value} />
                <HeaderDropdown data={year_data.data} id="year_list_dd" name="year_list_dd" selectedValue={seleted_year_value}/>
                

               <a className="top_switch_user" id='top_switch_user' onClick={handleUpdateDashboard}><i className="material-icons" style={{color:'#3663de', fontSize:'24px'}}>refresh</i></a>

               
            </div>}
         </div>
      </header>
   );
};

export default Topbar;
