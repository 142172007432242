import React from "react";

const ComingSoon = () => {

return(<>
    <div className="main-content">
        <div className="card text-center p-40">
        <h3>Coming Soon!</h3>
        </div>
      
    </div>
</>);

}

export default ComingSoon;