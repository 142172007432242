import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './../AuthContext'; // Import useAuth hook
import {apiDoLogin} from './../process/api';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login, isLoggedIn } = useAuth(); 
    const [isLoginError, setLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMsg] = useState('');
   
    

    const handleSubmit = async (event) => {
        event.preventDefault();
      
        var btnLogin =  document.getElementById('btnLogin');
        btnLogin.innerText ='Validating...';
        const apiEndpoint = '/api/auth/login'; 
        const body ={
            "username":username,
            "password":password
        }
        const apiResponse =  await apiDoLogin(apiEndpoint,body,'post');

            if(apiResponse.token){
                const isLoginSuccess =true;
                localStorage.setItem("token", apiResponse.token);
                login(apiResponse.token);
                navigate('/dashboard-loader'); 
                btnLogin.innerText ='Login';
                
            }else{
                //alert("Error: "+apiResponse.details);
                setLoginErrorMsg('Unable to login. '+apiResponse.details);
                setLoginError(true);
                btnLogin.innerText ='Login';
              
            }
        
        

        
    };

    return (
        <div className="container">
            <div className='custom-login-container'>
            <div className='row'>
        <div className='col-md-7'>

            <img src="../../images/login-left.png" alt="LEFT PROMO IMAGE" className='img-responsive' />
        </div>

        <div className='col-md-5 col-xs-12'>

<div className='login-outer-wrapper'>
        <img src="../../images/logo.png" alt='LOGO' className='brand-image' />
        <div className='login-wrapper'>
            <h2>SALES PORTAL</h2>
       
         
            <form onSubmit={handleSubmit}>

{isLoginError && <div className='alert alert-danger'>{loginErrorMessage}</div> }
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary btn-block btn-md" id='btnLogin'>Login</button>
            </form>
            </div>
            </div>

</div>
</div>  </div>
        </div>
    );
};

export default Login;
