import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from "react-router-dom";
import Layout from "./components/Layout";
import Dashboard from "./components/Dashboard";
import DashboardLoader from "./components/DashboardLoader";
import Login from "./components/Login";
import { AuthProvider, useAuth } from "./AuthContext"; // Ensure useAuth is exported from AuthContext
import HOReport from "./components/reports/HOReport";
import NW01 from "./components/reports/NW01";
import HST03 from "./components/reports/HST03";
import ComingSoon from "./components/ComingSoon";
import HST10 from "./components/reports/HST10";
import BI05 from "./components/reports/BI05";
import ST02 from "./components/reports/ST02";
import BI03 from "./components/reports/BI03";
import HY04 from "./components/reports/HY04";
import BI06 from "./components/reports/BI06";
import BI08 from "./components/reports/BI08";
import ST09 from "./components/reports/ST09";
import NW04 from "./components/reports/NW04";
import NW06 from "./components/reports/NW06";
import NW03 from "./components/reports/NW03";
import NW12 from "./components/reports/NW12";
import NW11 from "./components/reports/NW11";
import NW08 from "./components/reports/NW08";
import NW09 from "./components/reports/NW09";
import NW10 from "./components/reports/NW10";
import NW07 from "./components/reports/NW07";
import BL02 from "./components/reports/BL02";
import BM05 from "./components/reports/BM05";
import BL01 from "./components/reports/BL01";
import BI07 from "./components/reports/BI07";
import NW14 from "./components/reports/NW14";
import Users from "./components/Users";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard-loader" element={<DashboardLoader />} />
          {/* Apply the ProtectedRoute logic directly here */}
          <Route path="/" element={<ProtectedLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />

            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/report-product-wise-gross-net" element={<NW01 />} />
            <Route path="/report-product-group-wise-trend-bp-2" element={<NW03 />} />
            <Route path="/report-rupee-wise-performance" element={<NW04 />} />
            <Route path="/report-rupee-wise-gross-credit-net-sale" element={<NW06 />}  />
            <Route path="/report-selective-group-brn-hq-wise-sales-trend" element={<NW07 />}  />
            <Route path="/report-saleable-expiry-breakage" element={<NW08 />}  />
            <Route path="/report-expiry-ratio" element={<NW09 />}  />
            <Route path="/report-selective-group-product-wise-trend" element={<NW10 />}  />
            <Route path="/report-sales-review-sp-1" element={<NW12/>}  />
            <Route path="/report-rupee-wise-sales-analysis" element={<NW11/>}  />

            <Route path="/report-daily-rp" element={<BL02/>} />
            <Route path="/daily-billing-entry" element={<BL01/>} />
            <Route path="/report-product-wise-sales-detail" element={<BI07 />} />
            <Route path="/settings-users" element={<Users />} /> 
            <Route path="/report-iqvia" element={<NW14 />} />
            <Route path="/report-product-wise-this-year-last-year" element={<BM05 />} />
            <Route path="/report-product-wise-details" element={<HOReport />} />
            <Route
              path="/report-stockist-wise-trend-gross-credit-net"
              element={<HST03 />}
            />
            <Route
              path="/report-selective-product-stockiest-wise-trend"
              element={<HST10 />}
            />
            <Route
              path="/report-rupeeswise-salable-expiry-breakage"
              element={<BI03 />}
            />
            <Route
              path="/report-rupeeswise-gross-credit-net-sale"
              element={<BI05 />}
            />

            <Route
              path="/report-selective-product-hq-region-area-branch"
              element={<BI06 />}
            />
            <Route
              path="/report-selective-product-sales-trend-gross"
              element={<BI08 />}
            />


            <Route
              path="/report-selective-stockiest-product-wise-gross-credit-net-trend"
              element={<ST02 />}
            />
            <Route
              path="/report-selective-product-wise-stokiest-wise"
              element={<ST09 />}
            />

            <Route
              path="/report-ho-neary-expiry-list"
              element={<HY04 />}
            />


          </Route>

          <Route path="*" element={<Navigate to="/dashboard-loader" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

const ProtectedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname == "/") {
    navigate('/login');
  }

  const { isLoggedIn } = useAuth();
  const isAuth = isLoggedIn || localStorage.getItem("isLoggedIn") === "true";
  return isAuth ? <Layout /> : <Navigate to="/login" />;
};

export default App;
