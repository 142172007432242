import React, { useEffect, useState } from "react";
import ReportOptions from "../ReportOptions";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { proxy } from '../../process/api';
import DynamicDTFloat from "../DynamicDTFloat";
import DropDownControl from "../DropDownControl";
import BrandedLoader from "../BrandedLoader";
import { generateExcel, getHeaderColNames, excelColorCodes, transformNavDataForLabels } from "./../../utils";

import ProcessLoader from "../ProcessLoader";


const BL02 = () => {

    const navigate = useNavigate();

    const div_data = JSON.parse(localStorage.getItem('div_data'));
    const year_data = JSON.parse(localStorage.getItem('year_data'));
    const month_data = [
        { value: 1, name: "JAN" },
        { value: 2, name: "FEB" },
        { value: 3, name: "MAR" },
        { value: 4, name: "APR" },
        { value: 5, name: "MAY" },
        { value: 6, name: "JUN" },
        { value: 7, name: "JUL" },
        { value: 8, name: "AUG" },
        { value: 9, name: "SEP" },
        { value: 10, name: "OCT" },
        { value: 11, name: "NOV" },
        { value: 12, name: "DEC" }
    ];
    const depo_data = JSON.parse(localStorage.getItem('depo_data'));
    const allIndiaTypeDepo = depo_data.data.filter(item => item.value !== "NA"); //removed 0 from all type users

    const updatedDivData = [{ value: 0, name: "All" }];

    div_data.data.map((singleDiv, index) => {
        updatedDivData.push(singleDiv);
    });

    const today = new Date();

    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const [SelectedDate, setSelectedDate] = useState(`${day}/${month}/${year}`);

    const [showOptions, setShowOptions] = useState(true);
    const [SelectedDiv, setSelectedDIV] = useState(0);
    const [SelectedDepo, setSelectedDepo] = useState(allIndiaTypeDepo[0].value);
    const [SelectedStartMonth, setSelectedStartMonth] = useState(today.getMonth() + 1);

    const [SelectecReportType, setSelectecReportType] = useState(1);

    const [reportGenData, setreportGenData] = useState({});
    const [showProcessLoader, setShowProcessLoader] = useState(false);
    const [pageTitle, setPageTitle] = useState('');

    const handleExportSheet = async () => {

        var reportJson = {};
        reportJson.fileName = reportGenData.title + ".xlsx";
        reportJson.title = reportGenData.title;
        reportJson.author = "Aristo Pharma";
        reportJson.sheets = [
            {
                name: "Sheet 1",
                data: []
            }
        ]

        const columns = Object.keys(reportGenData.data[0]).filter(key => key !== 'color');
        const headerFormatted = columns.map(key => getHeaderColNames(key));
        reportJson.sheets[0].data.push({
            backgroundColor: "#0043a9",
            fontColor: "#FFFFFF",
            fontSize: 13,
            bold: true,
            values: headerFormatted
        });

        const backgroundColors = excelColorCodes;

        reportGenData.data.forEach(item => {
            const row = {
                backgroundColor: backgroundColors[item.color] || "", // Default to white if undefined
                fontColor: item.color === 2 ? "#FFFFFF" : "#333333",
                fontSize: 12,
                bold: false,
                values: columns.map(col => item[col])
            };
            reportJson.sheets[0].data.push(row);
        });

        generateExcel(reportJson).catch(console.error);

    }


    const handleReportGen = async () => {
        setShowProcessLoader(true);
        document.getElementById('btnReportGen').innerHTML = "PROCESSING...";

        var reportAPIResponse = {};
        const dateParts = document.getElementById('SelectedDate').value.split("/");
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        var payload = {};

        if (SelectecReportType != 3) {



            payload = {

                "div_code": SelectedDiv,

                "month": parseInt(SelectedStartMonth),
                "entry_date": formattedDate,
                "rep_type": SelectecReportType,

            };

            reportAPIResponse = await proxy(`/api/mis/dailyreport`, payload, "get");

        } else {

            payload = {
                "entry_date": formattedDate,
                "month": parseInt(SelectedStartMonth)
            };
            reportAPIResponse = await proxy(`/api/mis/dailystatus`, payload, "get");
        }


        setreportGenData(reportAPIResponse);
        setShowOptions(false);
        setShowProcessLoader(false);
    }

    const handleBranchChange = (branchVal) => {
        setSelectedDepo(branchVal);
    }

    const handleDivChange = (divVal) => {
        setSelectedDIV(divVal);
    }

    useEffect(() => {
        transformNavDataForLabels("BL02").then(title => {
            setPageTitle(title);
        });

    }, []);

    return (<>
        <div className="main-content">
            {showProcessLoader && <ProcessLoader />}
            {showOptions ?
                 <ReportOptions title={pageTitle} >

                    <div className="report-option-wrapper">

                        <div className="report-option-row">
                            <div className="report-option-item">
                                <label>Division</label>
                                <DropDownControl data={updatedDivData} id="report_div_list" name="report_div_list" onChange={(newVal) => { handleDivChange(newVal); }} />
                            </div>




                        </div>

                        <div className="report-option-row">

                            <div className="report-option-item ">
                                <input type="radio" name="bi05_type" className="mr-2" checked={SelectecReportType == 1} onChange={(val) => { setSelectecReportType(1) }} />
                                <label>HO&nbsp;Report</label>
                            </div>

                            <div className="report-option-item ml-20">
                                <input type="radio" name="bi05_type" className="mr-2" checked={SelectecReportType == 2} onChange={(val) => { setSelectecReportType(2) }} />
                                <label>Branch&nbsp;Report</label>
                            </div>

                            <div className="report-option-item ml-20">
                                <input type="radio" name="bi05_type" className="mr-2" checked={SelectecReportType == 3} onChange={(val) => { setSelectecReportType(3) }} />
                                <label>Updation&nbsp;Status </label>
                            </div>




                        </div>


                        <div className="report-option-row">


                            <div className="report-option-item">
                                <label>Billing&nbsp;Month</label>
                                <DropDownControl data={month_data} id="report_startyear_list" name="report_startyear_list" selectedValue={SelectedStartMonth} onChange={(val) => { setSelectedStartMonth(val) }} />
                            </div>
                            <div className="report-option-item ml-20">
                                <label className="mr-16">Billing&nbsp;Date</label>
                                <div class="input-group" data-provide="datepicker" data-orientation="bottom left" data-format="dd/mm/yyyy"  >
                                    <input type="text" class="form-control" id="SelectedDate" value={SelectedDate} />
                                    <div class="input-group-append picker">
                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>

                        </div>




                        <div className="report-option-action-row">
                            <button onClick={(e) => { navigate('/dashboard'); }} className="btn btn-outline btn-md btn-sec mr-10 fw-500">BACK</button>
                            <button onClick={handleReportGen} className="btn btn-md btn-primary pr-30 pl-30 fw-500 " id="btnReportGen" >SUBMIT</button>
                        </div>
                    </div>

                </ReportOptions>
                :
                <><div className="row">
                    <div className="col-12">
                        <div className="card report-card" style={{ width: "auto", minWidth: "100%" }}>
                            <div className="card-title report-card-title">{reportGenData.title}
                                <button className="btn btn-outline btn-md  mr-10 fw-500" onClick={(e) => { window.location.reload() }}>BACK</button>
                            </div>
                            <div className="card-body">
                                <DynamicDTFloat
                                    tableData={reportGenData}
                                    firstColWidth="70px"
                                    secColWidth="190px"
                                    handleExportSheet={handleExportSheet}
                                    fixedColumns={true}

                                />

                            </div>
                            <div className="card-footer">


                            </div>
                        </div>
                    </div>
                </div></>
            }
        </div>

    </>);
}

export default BL02;