import React, { useEffect, useState } from "react";
import ReportOptions from "../ReportOptions";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { proxy } from '../../process/api';
import DynamicDTFloat from "../DynamicDTFloat";
import DropDownControl from "../DropDownControl";
import BrandedLoader from "../BrandedLoader";
import { bm05_report_json_format, generateExcel ,getHeaderColNames, excelColorCodes,transformNavDataForLabels} from "../../utils";

import ProcessLoader from "../ProcessLoader";

const BM05 = () => {
    const navigate = useNavigate();

    const div_data = JSON.parse(localStorage.getItem('div_data'));
    const year_data = JSON.parse(localStorage.getItem('year_data'));
    const month_data = JSON.parse(localStorage.getItem('month_data'));
    const depo_data = JSON.parse(localStorage.getItem('depo_data'));
//    const allIndiaTypeDepo = depo_data.data.filter(item => item.value !== 0); //removed 0 from all type users
    const allIndiaTypeDepo = depo_data.data;

    const [showOptions, setShowOptions] = useState(true);
    const [SelectedDiv, setSelectedDIV] = useState(div_data.data[0].value);
    const [SelectedDepo, setSelectedDepo] = useState(allIndiaTypeDepo[0].value);
    const [SelectedYear, setSelectedYear] = useState(year_data.data[0].value);
    const [SelectedStartMonth, setSelectedStartMonth] = useState(month_data.data[0].value);
    const [SelectedEndMonth, setSelectedEndMonth] = useState(month_data.data[month_data.index - 1].value);
    const [SelectedRegion, setSelectedRegion] = useState(0);
    const [SelectedArea, setSelectedArea] = useState(0);
    const [SelectecReportType, setSelectecReportType] = useState(1);
    const [RegionList, setRegionList] = useState({ "data": [] });
    const [AreaList, setAreaLis] = useState({ "data": [] });
    const [reportGenData, setreportGenData] = useState({});
    const [unitValue, setUnitValue] = useState(1);
    const [repType, setRepType] = useState(1);
    const [ReportOptionType, setReportOptionType]   = useState(1);
    const [SelectedHQCode, setSelectedHQCode] = useState(1);
    const [HQvalues, setHQValues] = useState({ data: [] });
    const [SelectedHQ, setSelectedHQ] = useState(0);
    const [pageTitle, setPageTitle] = useState('');
    
    const [showProcessLoader, setShowProcessLoader] = useState(false);
    const [showHQLoading, setHQLoading] = useState(false);

    const loadRegionList = async (year, div, depo) => {
        if(depo == 0 ){return;}
        const regionAPIResponse = await proxy(`/api/mis/regionlist/${year}/${div}/${depo}`, {}, "get", true);
        setRegionList(regionAPIResponse);
        setSelectedRegion(regionAPIResponse.data[0].value);
    }

    const loadAreaList = async (year, div, depo) => {
        if(depo == 0 ){return;}

        const areaAPIResponse = await proxy(`/api/mis/arealist/${year}/${div}/${depo}`, {}, "get", true);
        setAreaLis(areaAPIResponse);
        setSelectedArea(areaAPIResponse.data[0].value);
    }
    
    const loadHQList = async (year, div, branch) => {
        if(branch == 0){return;}
        setHQLoading(true);
        const hqAPIResponse = await proxy(`/api/mis/hqlist/${year}/${div}/${branch}`, {}, "get");
        if (hqAPIResponse.data.length > 0) {
            setSelectedHQ(hqAPIResponse.data[0].value);
        } else {
            setSelectedHQ(0);
        }
        setHQValues(hqAPIResponse);
        setHQLoading(false);

    }


    const handleExportSheet = async () => {

        var reportJson = {};
        reportJson.fileName = reportGenData.title + ".xlsx";
        reportJson.title = reportGenData.title;
        reportJson.author = "Aristo Pharma";
        reportJson.sheets = [
            {
                name: "Sheet 1",
                data: []
            }
        ]

        const columns = Object.keys(reportGenData.data[0]).filter(key => key !== 'color');
        const headerFormatted = columns.map(key => getHeaderColNames(key));

        reportJson.sheets[0].data.push({
            backgroundColor: "#0043a9",
            fontColor: "#FFFFFF",
            fontSize: 13,
            bold: true,
            values: headerFormatted
        });

        const backgroundColors = excelColorCodes;

        reportGenData.data.forEach(item => {
            const row = {
                backgroundColor: backgroundColors[item.color] || "", // Default to white if undefined
                fontColor: item.color === 2 ? "#FFFFFF" : "#333333",
                fontSize: 12,
                bold: false,
                values: columns.map(col => item[col])
            };
            reportJson.sheets[0].data.push(row);
        });

        generateExcel(reportJson).catch(console.error);

    }



    const handleReportGen = async () => {
        setShowProcessLoader(true);
        document.getElementById('btnReportGen').innerHTML = "PROCESSING...";

       var hqCode = 0;

       if( SelectecReportType == 1){
        hqCode = SelectedHQ;
       }
       else if( SelectecReportType == 2){
        hqCode = SelectedRegion;
       }else if( SelectecReportType == 3 ){
        hqCode = SelectedArea;
       }else{
        hqCode = SelectedDepo;
       }

       var this_SelectecReportType = 0;
       if(SelectedDepo == 0){

       }else{
this_SelectecReportType =SelectecReportType;
       }
        const payload = {
            "myear": SelectedYear,
            "div_code": SelectedDiv,
            "depo_code": SelectedDepo,
            "smon": SelectedStartMonth,
            "emon": SelectedEndMonth,
            "rep_type": repType,
            
            "data_type":this_SelectecReportType,
            "uv": unitValue,
            "hq_code": hqCode,
            "option" : parseInt(ReportOptionType),
        };




        var reportAPIResponse = {};
        reportAPIResponse = await proxy(`/api/mis/branchmktrepo5`, payload, "get");
        setreportGenData(bm05_report_json_format(reportAPIResponse));
       
        setShowOptions(false);
        setShowProcessLoader(false);
    }

    const handleBranchChange = (branchVal) => {
        setSelectedDepo(branchVal);
        if(branchVal == 0 ){return;}
        loadRegionList(SelectedYear, SelectedDiv, branchVal);
        loadAreaList(SelectedYear, SelectedDiv, branchVal);
        loadHQList(SelectedYear,  SelectedDiv, branchVal);

    }

    const handleDivChange = (divVal) => {
        setSelectedDIV(divVal);
        loadRegionList(SelectedYear, divVal, SelectedDepo);
        loadAreaList(SelectedYear, divVal, SelectedDepo);
        loadHQList(SelectedYear,  divVal, SelectedDepo);
    }

    useEffect(() => {
        transformNavDataForLabels("BM05").then(title => {
            setPageTitle(title); 
        });

        loadRegionList(SelectedYear, SelectedDiv, SelectedDepo);
        loadAreaList(SelectedYear, SelectedDiv, SelectedDepo);
        loadHQList(SelectedYear,  SelectedDiv, SelectedDepo);
    }, []);
    return (<>
        <div className="main-content">
        {showProcessLoader && <ProcessLoader /> }
            {showOptions ?
                <ReportOptions title={pageTitle} >

                    <div className="report-option-wrapper">

                        <div className="report-option-row">
                            <div className="report-option-item">
                                <label>Division</label>
                                <DropDownControl data={div_data.data} id="report_div_list" name="report_div_list" onChange={(newVal) => { handleDivChange(newVal); }} />
                            </div>

                            <div className="report-option-item ml-20">
                                <label className="mr-16">Branch</label>
                                <DropDownControl data={allIndiaTypeDepo} id="report_branch_list" name="report_branch_list"

                                    onChange={handleBranchChange}
                                />
                            </div>


                        </div>
                        <div className="report-option-row">
                            <div className="report-option-item ">
                                <label>Mkt&nbsp;Year</label>
                                <DropDownControl data={year_data.data}
                                    id="report_mktyear_list" name="report_mktyear_list"
                                    onChange={(newYear) => {
                                        setSelectedYear(newYear);
                                        loadRegionList(newYear, SelectedDiv, SelectedDepo);
                                        loadAreaList(newYear, SelectedDiv, SelectedDepo);
                                        loadHQList(newYear,  SelectedDiv, SelectedDepo);
                                    }} />

                            </div>

                            <div className="report-option-item ml-20">
                                <label>From</label>
                                <DropDownControl data={month_data.data} id="report_startyear_list" name="report_startyear_list" selectedValue={SelectedStartMonth} onChange={(val) => { setSelectedStartMonth(val) }} />
                            </div>
                            <div className="report-option-item ml-10">
                                <label>To</label>
                                <DropDownControl data={month_data.data} id="report_endyear_list" name="report_endyear_list" selectedValue={SelectedEndMonth} onChange={(val) => { setSelectedEndMonth(val) }} />
                            </div>

                        </div>

                        <div className="report-option-row">
                            <div className="report-option-item">
                                <input type="radio" className="js-switched mr-10" name="report_uv" checked={(true && unitValue === 1)}
                                    onChange={(e) => { setUnitValue(1);; }} />
                                <label>Unit</label>
                            </div>
                            <div className="report-option-item ml-14">
                                <input type="radio" className="js-switched mr-10" name="report_uv" checked={(true && unitValue === 2)}
                                    onChange={(e) => { setUnitValue(2); }} />
                                <label>Value</label>
                            </div>

                        </div>

                        <div className="report-option-row">
                            <div className="report-option-item">
                                <input type="radio" className="js-switched mr-10" name="setRepType" checked={(true && repType === 1)}
                                    onChange={(e) => { setRepType(1);; }} />
                                <label>Productwise</label>
                            </div>
                            <div className="report-option-item ml-14">
                                <input type="radio" className="js-switched mr-10" name="setRepType" checked={(true && repType === 2)}
                                    onChange={(e) => { setRepType(2); setUnitValue(2);  }} />
                                <label>Groupwise</label>
                            </div>

                        </div>



                        <div className="report-option-row">
                                <div className="report-option-item">
                                    <input type="radio" className="js-switched mr-10" name="ReportOptionType" checked={(true && ReportOptionType === 1)}
                                        onChange={(e) => { setReportOptionType(1);; }} />
                                    <label>This&nbsp;Year&nbsp;Target/Sale</label>
                                </div>
                                <div className="report-option-item ml-14">
                                    <input type="radio" className="js-switched mr-10" name="ReportOptionType" checked={(true && ReportOptionType === 2)}
                                        onChange={(e) => { setReportOptionType(2); }} />
                                    <label>Last/This Year sale</label>
                                </div>

                              



                            </div>



                        <div className="report-option-row">

                        <div className="report-option-item ">
                                <input type="radio" name="bi05_type" className="mr-2" disabled={SelectedDepo ==0 } checked={SelectecReportType == 1} onChange={(val) => { setSelectecReportType(1) }} />
                                <label>HQ</label>

                            </div>



                            <div className="report-option-item ">
                                <input type="radio" name="bi05_type" className="mr-2"  disabled={SelectedDepo ==0 }  checked={SelectecReportType == 2} onChange={(val) => { setSelectecReportType(2) }} />
                                <label>Region</label>

                            </div>

                            <div className="report-option-item ml-20">
                                <input type="radio" name="bi05_type" className="mr-2"  disabled={SelectedDepo ==0 } checked={SelectecReportType == 3} onChange={(val) => { setSelectecReportType(3) }} />
                                <label>Area </label>


                            </div>
                            <div className="report-option-item ml-20">
                                <input type="radio" name="bi05_type" className="mr-2"  disabled={SelectedDepo ==0 } checked={SelectecReportType == 4} onChange={(val) => { setSelectecReportType(4) }} />
                                <label>Branch</label>


                            </div>

                        </div>

                        { SelectedDepo != 0 && SelectecReportType == 1 &&

<div className="report-option-row">
    <div className="report-option-item ">
        <label>Select&nbsp;Region</label>
        {showHQLoading ? <BrandedLoader /> :
                                            <DropDownControl
                                                data={HQvalues.data}
                                                id="report_hq_list"
                                                name="report_hq_list"
                                                onChange={(optionValue) => { setSelectedHQ(optionValue) }}
                                            />
                                        }
    </div>
</div>
}

                        {SelectedDepo != 0 &&  SelectecReportType == 2 &&

                            <div className="report-option-row">
                                <div className="report-option-item ">
                                    <label>Select&nbsp;Region</label>
                                    <DropDownControl data={RegionList.data} id="region_list" name="region_list" onChange={(val) => { setSelectedRegion(val); }} />
                                </div>
                            </div>
                        }
                        {SelectedDepo != 0 &&  SelectecReportType == 3 &&

                            <div className="report-option-row">
                                <div className="report-option-item ">
                                    <label>Select&nbsp;Area</label>
                                    <DropDownControl data={AreaList.data} id="area_list" name="area_list" onChange={(val) => { setSelectedArea(val); }} />
                                </div>
                            </div>
                        }



                        <div className="report-option-action-row">
                            <button onClick={(e) => { navigate('/dashboard'); }} className="btn btn-outline btn-md btn-sec mr-10 fw-500">BACK</button>
                            <button onClick={handleReportGen} className="btn btn-md btn-primary pr-30 pl-30 fw-500 " id="btnReportGen" >SUBMIT</button>
                        </div>
                    </div>

                </ReportOptions>
                :
                <><div className="row">
                    <div className="col-12">
                        <div className="card report-card" style={{ width: "max-content", minWidth: "100%" }}>
                            <div className="card-title report-card-title">{reportGenData.title}
                                <button className="btn btn-outline btn-md  mr-10 fw-500" onClick={(e) => { window.location.reload() }}>BACK</button>
                            </div>
                            <div className="card-body">
                                <DynamicDTFloat
                                    tableData={reportGenData}
                                    firstColWidth="110px"
                                    secColWidth="auto"
                                    handleExportSheet={handleExportSheet}
                                    fixedColumns={true}
                                />

                            </div>
                            <div className="card-footer">


                            </div>
                        </div>
                    </div>
                </div></>
            }

        </div>

    </>);
}

export default BM05;