import React, { useEffect } from "react";
import configData from "../config.json";

import {getHeaderColNames,formatToTwoDecimalPlaces} from "../utils";



const DynamicDT = ({ tableData, firstColWidth = "60px", secColWidth = "210px", handleExportSheet = () => { console.log("Event not defined for export.") }, highlightedDataCells=[] }) => {

  useEffect(() => {
    document.body.classList.add('sidebar-folded');
    window.handleExportExcel = handleExportSheet;

    return () => {
    
      window.handleExportExcel = handleExportSheet;

    };

  }, []);



  

  const headers = Object.keys(tableData.data[0]).filter(key => key !== 'color');

  const tableRowColors = ['table_rowtransparent', 'table_rowcolored', 'table_rowseccolored', 'table_rowthirdcolor', 'table_rowfourthcolor'];

  const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const alignData = (header, value) => {

    if (header === "code" || header === "pack" || header === "name") {
      return false;
    }
    return isNumeric(value);
  }

  const alignHeader = (header, index) => {

    if (header === "code" || header === "pack" || header === "name" || header == "month"
      || header == "time" || header == "entry_date" || header == "status" || header == "type"
      || header == "division" || header == "batch_no" || header == "expiry_date" || header == "desc"
      || header == "status" || header == "branch" || header == "branch_name" || header == "billing_date" || header == "remark") {
      return false;
    } else {
      return true;
    }
  }

  const headerTitle = (headerParam) => {
    if (configData.col_newheader_names[headerParam]) {
      return configData.col_newheader_names[headerParam].toUpperCase();
    } else {
      return headerParam.toUpperCase().replace(/_/g, ' ');
    }


  }
  const highlightDataCell = ( key ) => {

    if(highlightedDataCells.length == 0){
      return "";
    }

    if(highlightedDataCells.includes(key)){
      return "highlightedDataCell";
    }

    return "";

  }
  const getColWidth = (index) => {
    if (index == 0) {
      return firstColWidth;
    } else if (index == 1) {
      return secColWidth;
    } else {
      return "auto";
    }


  }
  return (
    <div className="" >
      <table className=" table table-striped " data-fixedHeader="true" cellspacing="0" id="reportDataTable" data-dom='plf<"toolbar">trip' data-provide="datatables"  >
        <thead>
          <tr>
            {headers.map((header, thindex) => (
              <th style={{ textAlign: alignHeader(header, thindex) ? 'right' : 'left', width: getColWidth(parseInt(thindex)) }} key={header}> {getHeaderColNames(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.data.map((row, index) => (
            <tr  key={index} className={tableRowColors[row.color]}>
              {headers.map((header, tdindex) => (
                <td className={highlightDataCell(header)}  style={{ whiteSpace: 'pre-wrap', textAlign: alignData(header, row[header]) ? 'right' : 'left', width: getColWidth(parseInt(tdindex)) }} key={header}>{ /(ach|gth)/.test(header) ? formatNumber(row[header]) : 
               <>{String(formatToTwoDecimalPlaces(row[header])).replace(/&nbsp;/g, '  ').trim()}</>}</td>
              ))}
            </tr>
          ))}
        </tbody>

      </table>
    </div>
  );
};

const formatNumber = (numericString) => {

  const number = parseFloat(numericString);
  if (!isNaN(number)) {
    const formatter = new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    var formattedNumber = formatter.format(number);
    const withoutCommas = formattedNumber.replace(/,/g, '');
    return withoutCommas;
  }

  return numericString; // Return original string if conversion fails
};


export default DynamicDT;