import React from 'react';

const CardDataPanel = ({heading, monthly_amount, comm_amount, bg_color, type}) => {
return (
    <div class="col-md-2">
                <div className={`card card-datac card-data-bottom ${type}`} style={{ backgroundColor: bg_color }}>
                  <div className={`card-content `} >

                 
                    
                    <div className='card-data'>{formatNumber(monthly_amount)} <span className='card-data-type'>Mth</span></div>
                    <div className='card-data'>{formatNumber(comm_amount)} <span className='card-data-type'>Cum</span></div>
                    <div className={`card-heading`}>{heading}</div>
                  </div>
                
                </div>
             </div>
);
}

const formatNumber = (numericString) => {
   
  const number = parseFloat(numericString);
  if (!isNaN(number)) {
    const formatter = new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2, 
    });
    
    var formattedNumber = formatter.format(number);
    const withoutCommas = formattedNumber.replace(/,/g, '');

    return withoutCommas;
    //return 
  }

  return numericString; // Return original string if conversion fails
};

export default CardDataPanel;
