import React from "react";


const Users = () => {


    return (
        <>
            <div class="main-content">

                <div className="row">
                    <div className="col-12">
                        <div className="card report-card" style={{ width: "auto", minWidth: "100%" }}>
                            <div className="card-title report-card-title">
                                Users
                            </div>
                            <div className="card-body">

                                <table className="table table-striped" cellspacing="0" data-dom='plf<"toolbar">trip' id="reportDataTable" data-provide="datatables"  >
                                    <thead>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Action</th>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>
                                                1
                                            </td>
                                            <td>Ankit Shukla</td>
                                            <td><button className="btn btn-sm btn-info btn-outline btn-bold "><i class="ti-pencil"></i> Edit</button></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                2
                                            </td>
                                            <td>Ankit Shukla</td>
                                            <td><button className="btn btn-sm btn-info btn-outline btn-bold "><i class="ti-pencil"></i> Edit</button></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                3
                                            </td>
                                            <td>Ankit Shukla</td>
                                            <td><button className="btn btn-sm btn-info btn-outline btn-bold "><i class="ti-pencil"></i> Edit</button></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                4
                                            </td>
                                            <td>Ankit Shukla</td>
                                            <td><button className="btn btn-sm btn-info btn-outline btn-bold "><i class="ti-pencil"></i> Edit</button></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                5
                                            </td>
                                            <td>Ankit Shukla</td>
                                            <td><button className="btn btn-sm btn-info btn-outline btn-bold "><i class="ti-pencil"></i> Edit</button></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                6
                                            </td>
                                            <td>Ankit Shukla</td>
                                            <td><button className="btn btn-sm btn-info btn-outline btn-bold "><i class="ti-pencil"></i> Edit</button></td>
                                        </tr>

                                    </tbody>
                                </table>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Users;