import React from "react";
import { BeatLoader } from "react-spinners";


const BrandedLoader = ({isVisible = true}) =>{

return(
    <BeatLoader color="#1940a9" />
);

}

export default BrandedLoader;