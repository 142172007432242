import { jwtDecode } from "jwt-decode";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { json } from "react-router-dom";
import {proxy} from './process/api';
import configData from "./config.json";

export function formatToTwoDecimalPlaces(value, currentKey ,force = false, decimalKeys = []) {

 
  if(force && decimalKeys.includes(currentKey)){
    return value.toFixed(2);
  }

  if (typeof value === 'number') {
      if (value % 1 !== 0) {
          return value.toFixed(2);
      }
      return value.toString();
  }
  return value;
}

export function excelFontColor (itemColor){

  return itemColor === 2 || itemColor === 3 ? "#FFFFFF" : "#333333"
} 


export function goFullScreen()
{
  if (!document.fullscreenElement) {
    // Enter full-screen mode
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) { // Firefox
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari, Opera
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
      document.documentElement.msRequestFullscreen();
    }
  
  } else {
    // Exit full-screen mode
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { // IE/Edge
      document.msExitFullscreen();
    }
    
  }
}
export const excelColorCodes = {
  0: "", // 0
  1: "#5892C6", // 1
  2: "#3553DE",  // 2
  3: "#1940A9",
  4: "#bbd4e8" // highights
};


export async function transformNavDataForLabels (reportID) {
  const loginMenuApiResponse  = await proxy(`/api/mis/loginmenu`,{}, "get", true);
  const labelWithTitles =  loginMenuApiResponse.data.reduce((acc, tab) => {
    tab.menu_list.forEach(menu => {
      acc[menu.repo_id] = menu.repo_name.toString().replace(".","");
    });
    return acc;
  }, {});
    return labelWithTitles[reportID];
}
function toTitleCase(str) {
  return str.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
}


export function getHeaderColNames(headerParam) {


  if (configData.col_newheader_names[headerParam]) {
    return configData.col_newheader_names[headerParam];
  } else {
    return toTitleCase(headerParam.toUpperCase()
    .replace(/_PER/g, '%')
    .replace(/PER/g, '%')
          .replace(/_/g, ' ')
          .replace(/_SLASH/g, ' / ')
          .replace(/SLASH/g, ' / ').replace(/DEFF/g,'DEF+-')
          .replace(/DEF/g,'DEF+-'));
  }

}

export function getLoggedInUsername() {
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  return decodedToken.fname;
}

export function getUserType() {
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  return decodedToken.usertype;

}

export function nw01_report_json_format(json_input) {
  const processedData = json_input.data.map(item => {
    const { branches, ...rest } = item;
    return { ...rest, ...branches };
  });
  const processed_json = {
    ...json_input,
    data: processedData
  };
  return processed_json;
}


export function hst03_report_json_format(json_input) {
  const processedData = json_input.data.map(item => {
    const { months, ...rest } = item;
    return { ...rest, ...months };
  });
  const processed_json = {
    ...json_input,
    data: processedData
  };

  return processed_json;
}

export function bm05_report_json_format(json_input) {
  const processedData = json_input.data.map(item => {
    const { months, ...rest } = item;

    return { ...rest, ...months };
  });
  const processed_json = {
    ...json_input,
    data: processedData,

  };

  var { data, ...rest_data } = processed_json;
  var new_processed_data = data.map(item => { if (item.code == "0") item.code = ""; return item; });
  const new_processed_json = {
    ...rest_data,
    data: new_processed_data
  }
  console.log(new_processed_json);

  return new_processed_json;
}


export function nw10_report_json_format(json_input) {


  var processed_json = {};

  processed_json.title = json_input.title;
  processed_json.no_of_records = json_input.no_of_records;
  processed_json.index = json_input.index;
  processed_json.data = [];


  json_input.data.map((item, index) => {
    const { name, color, ...rest } = item;
    const transformedData = {
      fs: { name, ...rest.fs },
    };

    Object.keys(rest).forEach(key => {
      if (key !== 'fs') {
        transformedData[key] = { name: '', ...rest[key] };
      }
    });

    Object.keys(transformedData).forEach(key => {
      const { name, ...rest } = transformedData[key];
      const newItem = { name: name, type: getHeaderColNames(key), color: (name == "" ? 0 : 1), ...rest };
      processed_json.data.push(newItem);
    });

  });

  return processed_json;
}


export function nw04_report_json_format(json_input) {


  var processed_json = {}

  processed_json.title = json_input.title;


  processed_json.data = [];
  Object.entries(json_input.data[0]).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      const newValue = { name: getHeaderColNames(key) };
      const color = { color: 0 };
      Object.assign(newValue, value);
      Object.assign(newValue, color);
      json_input.data[0][key] = newValue;
    }
  });


  Object.entries(json_input.data[0]).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      processed_json.data.push(value);
    }
  });

  return processed_json;



}
export async function generateExcel(jsonData, widthColA = 20, widthcolB = 40, colFreeze = 0, rowFreeze = 3, multiReportTitle = []) {
  const workbook = new ExcelJS.Workbook();
  const today = new Date();

  workbook.creator = jsonData.author;
  workbook.created = today;
  workbook.title = jsonData.title;

  var sheet_counter = 0;
  jsonData.sheets.forEach(sheetData => {
    const sheet = workbook.addWorksheet(sheetData.name);

    const numberOfColumns = sheetData.data[0].values.length;

    const maxCharactersPerColumn = calculateMaxCharactersPerColumn(sheetData);

    sheet.columns = maxCharactersPerColumn.map((maxChar, index) => {
      const width = characterCountToWidth(maxChar);
      const columnLetter = String.fromCharCode('A'.charCodeAt(0) + index);
      return { key: columnLetter, width: width };
    });



    const titleRow = sheet.addRow(["Aristo Pharmaceuticals Private Limited"]);
    // sheet.mergeCells(1, 1, 1, numberOfColumns);
    titleRow.getCell(1).alignment = { horizontal: 'left' };
    titleRow.getCell(1).font = { bold: true, size: 12 };

    let sheet_title = "";
    if (multiReportTitle.length == 0) {
      sheet_title = jsonData.title;
    } else {
      sheet_title = multiReportTitle[sheet_counter++];
    }
    const titleRow2 = sheet.addRow([sheet_title]);
    // sheet.mergeCells(2, 1, 2, numberOfColumns);
    titleRow2.getCell(1).alignment = { horizontal: 'left' };
    titleRow2.getCell(1).font = { bold: true, size: 12 };

    sheet.views = [
      { state: 'frozen', xSplit: colFreeze, ySplit: rowFreeze, topLeftCell: 'C4', activeCell: 'A4' }
    ];

    sheetData.data.forEach((rowData, rowIndex) => {
      const row = sheet.addRow(rowData.values, 'n', { cellStyles: true });

      rowData.values.forEach((_, colIndex) => {
        const cell = row.getCell(colIndex + 1);
        if (rowData.backgroundColor) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: rowData.backgroundColor.replace('#', '') },
          };
        }
        if (rowData.fontColor) {
          cell.font = {
            color: { argb: rowData.fontColor.replace('#', '') },
            size: rowData.fontSize,
            bold: rowData.bold,
          };
        }
      });
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const timestamp = getCurrentDateTime();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(blob, jsonData.fileName.replace(".xlsx", `-TS-${timestamp}.xlsx`));
};

function getCurrentDateTime(){
  const date = new Date();
  const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const formattedDateTime = `${day}-${month}-${year}-${hours}${minutes}HRS`;
  return formattedDateTime;
};


function calculateMaxCharactersPerColumn(sheet) {

  const maxChars = [];

  sheet.data.forEach(row => {
    row.values.forEach((value, index) => {

      if (value) {
        const stringValue = value.toString(); // Convert everything to string to measure length
        const length = stringValue.length;

        if (typeof maxChars[index] === 'undefined' || length > maxChars[index]) {
          maxChars[index] = length;
        }
      }

    });
  });

  return maxChars; // Returns array of max characters for each column

}



function characterCountToWidth(charCount) {
  const baseWidth = 3;
  const padding = 2;
  return charCount + padding > baseWidth ? charCount + padding : baseWidth;
}

