import React from "react";

const ReportOptions = ({ title, children, widthClass="" }) => {
  return (
    <div className="reportOptionOverlay">
    <div  className={`report-option-card  ${widthClass}`} >
      <div className="report-optios-header">{title}</div>
      <div className="report-options-body">{children}</div>
    </div>
    </div>
  );
};

export default ReportOptions;
