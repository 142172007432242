import React from "react";
import BrandedLoader from "./BrandedLoader"

const MultiSheetProcessingLoader = ({title = "Wait", loadingText = ""})=>{

    return(<>
     <div className="reportOptionOverlay">
    <div className="report-option-card">
    <div className="report-optios-header">{title}</div>
    <div className="report-options-body" style={{minHeight:"100px"}}>
        <BrandedLoader/> <div id="sheet_loading_message">{loadingText}</div>
    </div>
    </div>
    </div>
    </>);
}


export default MultiSheetProcessingLoader