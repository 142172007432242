import React from "react";
import configData from "./../config.json";

import { useAuth } from './../AuthContext'; 
const Preview = () => {

    const { hasPreviewContent } = useAuth(); 

   
    return(<>
{hasPreviewContent != '' &&
        <div id="reportPreview">
            <div id="previewContainer" >
            <div className="report-optios-header" style={{backgroundColor:"#efefef", color:"#333"}}>Preview</div>
                <img src={`../../images/reports_preview/${hasPreviewContent}.jpg?v=${configData.version_label}`} style={{width:"100%", opacity:0.95, backgroundColor:"#000"}} />
                <div className="specimencopyText">SPECIMEN COPY</div>
            </div>

        </div>
}
    </>);
}

export default Preview;