import React from 'react';

const HeaderDropdown = ({ id, name, data, selectedValue }) => {
  const defaultValue = selectedValue || (data.length > 0 ? data[0].value : '');

 
  return (
    <select name={name} id={id} defaultValue={defaultValue}>
      {data.map((item) => (
        <option key={item.value} value={item.value}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

export default HeaderDropdown;
