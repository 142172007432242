import React, { useEffect, useState } from "react";
import ReportOptions from "../ReportOptions";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { proxy } from '../../process/api';
import DynamicDTFloat from "../DynamicDTFloat";
import DynamicDTFloatTabs from "../DynamicDTFloatTabs";
import DropDownControl from "../DropDownControl";
import BrandedLoader from "../BrandedLoader";
import { nw04_report_json_format, generateExcel,getHeaderColNames, excelColorCodes,transformNavDataForLabels,   goFullScreen } from "../../utils";

import MultiSelectOptions from "./../MultiSelectOptions";
import MultiSheetProcessingLoader from "../MultiSheetProcessingLoader";
import ProcessLoader from "../ProcessLoader";


const NW04 = () => {

    const navigate = useNavigate();

    const div_data = JSON.parse(localStorage.getItem('div_data'));
    const year_data = JSON.parse(localStorage.getItem('year_data'));
    const month_data = JSON.parse(localStorage.getItem('month_data'));
    const depo_data = JSON.parse(localStorage.getItem('depo_data'));

    const [showOptions, setShowOptions] = useState(true);
    const [reportGenData, setreportGenData] = useState({});
    const [SelectedDiv, setSelectedDIV] = useState(div_data.data[0].value);
    const [SelectedYear, setSelectedYear] = useState(year_data.data[0].value);
    const [SelectedDepo, setSelectedDepo] = useState(depo_data.data[0].value);
    const [SelectedStartMonth, setSelectedStartMonth] = useState(month_data.data[0].value);
    const [SelectedEndMonth, setSelectedEndMonth] = useState(month_data.data[month_data.index - 1].value);
    const [UnitValue, setUnitvalue] = useState(1);
    const [ReportType, setReportType] = useState(1);
    const [ReportOption, setReportOption] = useState(1);
    const [groupLoader, setGroupLoader] = useState(true);
    const [GroupListItems, setGroupListItems] = useState([]);
    const [SelectedGroup, setSelectedGroup] = useState(0);
    const [productReportData, setProductReportdata] = useState([]);
    const [tabLoader, setTabLoader] = useState([]);
    const [sheetloaderMessage, setSheetLoaderMessage] = useState("");

    const [reportGenerationCompleted, setReportGenerationCompleted] = useState(false);
    const [reportDataReady, setReportDataReady] = useState(false);
    const [MultiSheetDownloadStart, setMultiSheetDownloadStart] = useState(false);
    const [showProcessLoader, setShowProcessLoader] = useState(false);
    const [pageTitle, setPageTitle] = useState('');


    useEffect(() => {

        if (reportGenerationCompleted) {
            setReportDataReady(true);
        }
    }, [reportGenerationCompleted]);



    useEffect(() => {
        if (reportDataReady) {
            var multiReportTitle = [];

            var reportJson = {};
            reportJson.fileName = "Rupeewise Sales Performance-" + SelectedYear + ".xlsx";
            reportJson.title = "Rupeewise Sales Performance";
            reportJson.author = "Aristo Pharma";


            reportJson.sheets = [];
            productReportData.map((singlereport, index) => {

                reportJson.sheets.push({
                    name: SelectedGroup[index].value.toString().trim(),
                    data: []
                })
                multiReportTitle.push(singlereport.data.title);

                if (singlereport.data.data.length > 0) {

                    const columns = Object.keys(singlereport.data.data[0]).filter(key => key !== 'color');
                    const headerFormatted = columns.map(key => getHeaderColNames(key));



                    reportJson.sheets[index].data.push({
                        backgroundColor: "#0043a9",
                        fontColor: "#FFFFFF",
                        fontSize: 13,
                        bold: true,
                        values: headerFormatted
                    });

                    const backgroundColors = excelColorCodes;

                    singlereport.data.data.forEach(item => {
                        const row = {
                            backgroundColor: backgroundColors[item.color] || "",
                            fontColor: item.color === 2 ? "#FFFFFF" : "#333333",
                            fontSize: 12,
                            bold: false,
                            values: columns.map(col => item[col])
                        };
                        reportJson.sheets[index].data.push(row);
                    });

                } else {

                    const columns = ['NoData'];
                    const item = { "NoData": "No Data" }
                    const headerFormatted = ["No Data"];
                    reportJson.sheets[index].data.push({
                        backgroundColor: "#0043a9",
                        fontColor: "#FFFFFF",
                        fontSize: 13,
                        bold: true,
                        values: headerFormatted
                    });
                    const row = {
                        backgroundColor: "",
                        fontColor: "#333333",
                        fontSize: 12,
                        bold: false,
                        values: columns.map(col => item[col])
                    };
                    reportJson.sheets[index].data.push(row);

                }
            });

            generateExcel(reportJson, 40, 20, 0, 3, multiReportTitle).catch(console.error);

            setReportDataReady(false);
            setMultiSheetDownloadStart(false);
            setReportGenerationCompleted(false);
        }
    }, [reportDataReady]);


    const generateProductReport = async (productCode) => {
        const payload = {
            "myear": parseInt(SelectedYear),
            "div_code": parseInt(SelectedDiv),
            "depo_code": parseInt(SelectedDepo),
            "smon": parseInt(SelectedStartMonth),
            "emon": parseInt(SelectedEndMonth),
            "gp_code": parseInt(productCode),

        }
        var reportAPIResponse = {};
        reportAPIResponse = await proxy(`/api/mis/mktrepo4`, payload, "get");


        var processedReportData = [];

        if (reportAPIResponse == '') {


            processedReportData = { title: "NO DATA", data: [] };

        } else {

            processedReportData = nw04_report_json_format(reportAPIResponse);
        }


        const newProductReport = { "product_code": productCode, "data": processedReportData };
        setProductReportdata((productReportData) => {
            const index = productReportData.findIndex(p => p.product_code === newProductReport.product_code);
            if (index > -1) {
                return productReportData.map((p, i) => i === index ? newProductReport : p);
            } else {

                return [...productReportData, newProductReport];
            }
        });


        setTabLoader([...tabLoader, productCode]);


    }

    function getTabreport(pcode) {

        const index = productReportData.findIndex(item => item.product_code === pcode);
        return productReportData[index].data;

    }



    const handleGroupChange = (selectedGroup) => {
        if (selectedGroup.length > 20) {
            alert('Maximum of 20 products can be selected at a time. Please remove extra products.');
        } else {
            setSelectedGroup(selectedGroup);
        }
    }


    const handleExportSheet = async () => {

        var reportJson = {};
        reportJson.fileName = reportGenData.title + ".xlsx";
        reportJson.title = reportGenData.title;
        reportJson.author = "Aristo Pharma";

        reportJson.sheets = [
            {
                name: "Sheet 1",
                data: []
            }
        ]

        const columns = Object.keys(reportGenData.data[0]).filter(key => key !== 'color');
        const headerFormatted = columns.map(key => getHeaderColNames(key));

        reportJson.sheets[0].data.push({
            backgroundColor: "#0043a9",
            fontColor: "#FFFFFF",
            fontSize: 13,
            bold: true,
            values: headerFormatted
        });

        const backgroundColors = {
            0: "", // 0
            1: "#5892C6", // 1
            2: "#3553DE"  // 2
        };

        reportGenData.data.forEach(item => {
            const row = {
                backgroundColor: backgroundColors[item.color] || "",
                fontColor: item.color === 2 ? "#FFFFFF" : "#333333",
                fontSize: 12,
                bold: false,
                values: columns.map(col => item[col])
            };
            reportJson.sheets[0].data.push(row);
        });

        generateExcel(reportJson).catch(console.error);

    }

    const handleReportGen = async () => {

        goFullScreen();
        if (ReportType == 2) {
            document.getElementById('btnReportGen').innerHTML = "PROCESSING...";
            var groupCode = 0;
            const payload = {
                "myear": parseInt(SelectedYear),
                "div_code": parseInt(SelectedDiv),
                "depo_code": parseInt(SelectedDepo),
                "smon": parseInt(SelectedStartMonth),
                "emon": parseInt(SelectedEndMonth),
                "gp_code": parseInt(groupCode),

            }
            var reportAPIResponse = {};
            reportAPIResponse = await proxy(`/api/mis/mktrepo4`, payload, "get");

            const new_data = nw04_report_json_format(reportAPIResponse);
            console.log(new_data);
            setreportGenData(new_data);
            setShowOptions(false);

        } else {

            if (SelectedGroup.length == 0) {
                alert('Select aleast one group');
                return;
            }
            generateProductReport(SelectedGroup[0].value);
            setShowOptions(false);
        }

        return;
    }


    const handleTabChange = (groupCode) => {
        if (!tabLoader.includes(groupCode)) {
            generateProductReport(groupCode);
        } else {

        }
    }


    const handleExportMultiSheet = async () => {

        setMultiSheetDownloadStart(true);
        if (SelectedGroup.length !== productReportData.length) {
            for (let i = 0; i < SelectedGroup.length; i++) {
                setSheetLoaderMessage("Processing data for group #" + SelectedGroup[i].value);
                await generateProductReport(SelectedGroup[i].value);
            }
            setReportGenerationCompleted(true);
        } else {
            setReportGenerationCompleted(true);
        }

    }


    const loadGroupList = async (div, year) => {
        setGroupLoader(true);
        const grouplistAPIResponse = await proxy(`/api/mis/grouplist/${div}/${year}`, {}, "get", true);
        const converted_list = convertGroupData(grouplistAPIResponse);
        setGroupListItems(converted_list);
        setGroupLoader(false);
        setSelectedGroup(converted_list[0]);
    }


    const handleBranchChange = async (newValue) => {
        setSelectedDepo(newValue);
    }

    function convertGroupData(dataAPIResponse) {
        if (dataAPIResponse && Array.isArray(dataAPIResponse.data)) {
            const converted_list = dataAPIResponse.data.map(item => ({
                value: item['value'],
                label: item['name']
            }));
            return converted_list;
        }
        return [];
    }


    useEffect(() => {
        loadGroupList(SelectedDiv, SelectedYear);
        transformNavDataForLabels("NW04").then(title => {
            setPageTitle(title); 
        });


    }, []);

    return (
        <>
            <div class="main-content">
            {showProcessLoader && <ProcessLoader /> }
                {showOptions ?
                      <ReportOptions title={pageTitle} >
                        <div className="report-option-wrapper">
                            <div className="report-option-row">
                                <div className="report-option-item">
                                    <label>Division</label>
                                    <DropDownControl data={div_data.data} id="report_div_list" name="report_div_list" onChange={(newVal) => { setSelectedDIV(newVal); loadGroupList(newVal, SelectedYear); }} />
                                </div>

                                <div className="report-option-item ml-20">
                                    <label>Branch</label>
                                    <DropDownControl data={depo_data.data} id="report_branch_list" name="report_branch_list"

                                        onChange={(newBranch) => { handleBranchChange(newBranch); }}
                                    />
                                </div>

                            </div>

                            <div className="report-option-row">
                                <div className="report-option-item ">
                                    <label>Mkt&nbsp;Year</label>
                                    <DropDownControl data={year_data.data} id="report_mktyear_list" name="report_mktyear_list" onChange={(newYear) => { console.log(newYear); setSelectedYear(newYear) }} />

                                </div>

                                <div className="report-option-item ml-10">
                                    <label>From</label>
                                    <DropDownControl data={month_data.data} id="report_startyear_list" name="report_startyear_list" selectedValue={SelectedStartMonth} onChange={(val) => { console.log(val); setSelectedStartMonth(val) }} />
                                </div>
                                <div className="report-option-item ml-10">
                                    <label>To</label>
                                    <DropDownControl data={month_data.data} id="report_endyear_list" name="report_endyear_list" selectedValue={SelectedEndMonth} onChange={(val) => { console.log(val); setSelectedEndMonth(val) }} />
                                </div>

                            </div>
                            <div className="report-option-row">
                                <div className="report-option-item">
                                    <input type="radio" class="js-switched mr-10" name="report_branchhq" checked={(true && ReportType === 1)}
                                        onChange={(e) => { setReportType(1); }} />
                                    <label>Groupwise</label>
                                </div>
                                <div className="report-option-item ml-10">
                                    <input type="radio" class="js-switched mr-10" name="report_branchhq" checked={(true && ReportType === 2)}
                                        onChange={(e) => { setReportType(2); }} />
                                    <label>All</label>
                                </div>
                            </div>



                            {ReportType == 1 &&
                                <div className="report-option-row">
                                    <div className="report-option-item">
                                        <label>Group</label>


                                        {groupLoader ? <BrandedLoader /> :
                                            <MultiSelectOptions
                                                itemsData={GroupListItems}
                                                onChange={handleGroupChange}
                                            //   defValue={GroupListItems[0]}


                                            />
                                        }
                                    </div>

                                </div>
                            }


                            <div className="report-option-action-row">
                                <button onClick={(e) => { navigate('/dashboard'); }} className="btn btn-outline btn-md btn-sec mr-10 fw-500">BACK</button>
                                <button onClick={handleReportGen} className="btn btn-md btn-primary pr-30 pl-30 fw-500 " id="btnReportGen" >SUBMIT</button>
                            </div>
                        </div>
                    </ReportOptions>
                    :
                    <>
                    {ReportType == 1 ?
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card report-card" style={{ width:"auto" }}>
                                        <div className="card-title report-card-title">{'Rupeewise Sales Performance'}
                                            <button className="btn btn-outline btn-md  mr-10 fw-500" onClick={(e) => { window.location.reload() }}>BACK</button>
                                        </div>
                                        <div className="card-body card-body-multireport">


                                            <ul class="nav nav-tabs nav-tabs-light-mode nav-justified" role="tablist">

                                                {SelectedGroup.map((product, index) => (

                                                    <li key={product.value} class="nav-item ">
                                                        <a class={`nav-link ${index == 0 && 'active'}`} onClick={() => { handleTabChange(product.value) }} data-toggle="tab" href={`#tab${product.value}`} role="tab">{product.label}</a>
                                                    </li>

                                                ))}

                                            </ul>

                                            <div class="tab-content">
                                                {SelectedGroup.map((product, index) => (

                                                    <div key={product.value} className={`tab-pane fade ${index == 0 && 'active show'}`} id={`tab${product.value}`}>

                                                        {
                                                            tabLoader.includes(product.value) ?

                                                                <>
                                                                    <h3 className="fs-16 fw-500">{getTabreport(product.value).title}</h3>

                                                                    {getTabreport(product.value).data.length > 0 ? <DynamicDTFloatTabs
                                                                     fixedColumns={true}
                                                                        tableData={getTabreport(product.value)}
                                                                        tableID={`tabledt_${product.value}`}
                                                                        firstColWidth="220px"
                                                                        secColWidth="90px"
                                                                    
                                                                       handleExportSheet={handleExportMultiSheet}
                                                                    
                                                                    /> : <p className="alert alert-secondary" role="alert">No data for this product.</p>}

                                                                </>
                                                                : <BrandedLoader />
                                                        }


                                                    </div>
                                                ))}


                                            </div>

                                        </div>
                                        <div className="card-footer">


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card report-card" style={{ width: "auto" }}>
                                        <div className="card-title report-card-title">{reportGenData.title}
                                            <button className="btn btn-outline btn-md  mr-10 fw-500" onClick={(e) => { window.location.reload() }}>BACK</button>
                                        </div>
                                        <div className="card-body">
                                            <DynamicDTFloat
                                                tableData={reportGenData}
                                             
                                                handleExportSheet={handleExportSheet}
                                                firstColWidth="200px"
                                                secColWidth="100px"
                                                fixedColumns={true}
                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    </>
                }
                {MultiSheetDownloadStart && <MultiSheetProcessingLoader title="Please wait while we download the excel file." loadingText={sheetloaderMessage} />}

            </div>
        </>
    );


}

export default NW04;