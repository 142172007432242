import React, { useEffect,useState } from "react";
import configData from "../config.json";
import {getHeaderColNames,formatToTwoDecimalPlaces} from "../utils";
import $ from 'jquery';

import 'datatables.net';
import 'datatables.net-fixedheader';
import 'datatables.net-fixedcolumns';



const DynamicDT = ({ tableData, firstColWidth = "60px", secColWidth = "210px", handleExportSheet = () => { console.log("Event not defined for export.") }, fixedHeader = false, fixedColumns = false, fixedColCount = 2, forceDecimal = false, decimalKeys = [], highlightedDataCells=[] }) => {
 
  const [docHeight, setDocHeight] = useState(window.innerHeight);

  const highlightDataCell = ( key ) => {

    if(highlightedDataCells.length == 0){
      return "";
    }

    if(highlightedDataCells.includes(key)){
      return "highlightedDataCell";
    }

    return "";

  }

  useEffect(() => {

    document.body.classList.add('sidebar-folded');
    window.handleExportExcel = handleExportSheet;

    
    console.log(docHeight);
    const frameHeight = docHeight - 450;
    console.log(frameHeight);

    if ($.fn.dataTable.isDataTable('#reportDataTable')) {
      $('#reportDataTable').DataTable().clear().destroy();
    }

    var dataTableConfig = {
      language: {
        paginate: {
            next: 'Next',
            previous: 'Previous'
        }
    },
      pagingType: 'simple_numbers',
      lengthMenu: [
        [50, 100, 150, 200],
        [50, 100, 150, 200]
      ],
      dom: '<"toolbar">frtip',
      ordering: false,
      fnInitComplete: function () {
        $('div.toolbar').html('<a onClick="window.handleExportExcel()" class="btn btn-pure"><img src="./images/export-excel.png" alt="Export Excel"/></a>');

        var lengthControl = $('.dt-length');
        var select = lengthControl.find('select');
        
        // Create the new structure
        var newDiv = $('<div>', {
            class: 'dataTables_length custom_dt_length',
            id: 'reportDataTable_length'
        });
        var label = $('<label>').text('Show ');
        select.addClass('form-control form-control-sm');
        label.append(select).append(' entries');
        newDiv.append(label);
        
        // Replace the old structure with the new one
        lengthControl.replaceWith(newDiv);
        // $('.dt-paging-button.previous').html("Previous");
        // $('.dt-paging-button.next').html("Next");
        $('#reportDataTable colgroup:not(:first)').remove();
     
     
      },



    };

    if (fixedHeader) {
      dataTableConfig.fixedHeader = true;
    
    } else {
      dataTableConfig.fixedHeader = false;
    }

    if (fixedColumns && fixedColCount == 1) {
      dataTableConfig.fixedColumns = true;
      dataTableConfig.scrollCollapse = false;
      dataTableConfig.scrollX = true;
      dataTableConfig.scrollY = frameHeight;
    } else if (fixedColumns && fixedColCount > 1) {
      dataTableConfig.fixedColumns = { start: fixedColCount };
      dataTableConfig.scrollCollapse = false;
      dataTableConfig.scrollX = false;
      dataTableConfig.scrollY = frameHeight;
    }

    var tableHandler = $('#reportDataTable').DataTable(dataTableConfig);

    const manualDraw = () => {
      tableHandler.draw();
    };

    // Trigger manual draw after some action (for demonstration)
    setTimeout(manualDraw, 1000); 
   
    if(fixedHeader){
      setTimeout( ()=>{$(window).trigger('resize')},600);
    }
    setTimeout( ()=>{$(window).trigger('resize')},300);

    // Clean up by resetting the global function to a placeholder
    return () => {
      $('#reportDataTable').DataTable().destroy();
      window.handleExportExcel = handleExportSheet;

    };

  }, []);




  const headers = Object.keys(tableData.data[0]).filter(key => key !== 'color');

  const tableRowColors = ['table_rowtransparent', 'table_rowcolored', 'table_rowseccolored', 'table_rowthirdcolor','table_rowfourthcolor'];

  const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const alignData = (header, value) => {

    if (header === "code" || header === "pack" || header === "name") {
      return false;
    }
    return isNumeric(value);
  }

  const alignHeader = (header, index) => {

    if (header === "code" || header === "pack" || header === "name" || header == "month"
      || header == "time" || header == "entry_date" || header == "status" || header == "type"
      || header == "division" || header == "batch_no" || header == "expiry_date" || header == "product" || header == "company"
      || header == "status" || header == "branch" || header == "branch_name" || header == "billing_date" || header == "remark") {
      return false;
    } else {
      return true;
    }
  }

  
  const handleFloatingHeaderVisibility = ()=>{

    if(fixedColumns && fixedHeader ){
      return "dynamicDTFloatHead";
    }else if(fixedColumns){
      return "dynamicDTFloatHead";
    }else{
      return "";
    }

  }
  const getColWidth = (index) => {
    if (index == 0) {
      return firstColWidth;
    } else if (index == 1) {
      return secColWidth;
    } else {
      return "auto";
    }


  }
  return (
    <div className="" >
      <table style={{ marginBottom: "0px !important", marginTop: "0px !important",   }} className=" table table-striped  floatingDTable" cellspacing="0" id="reportDataTable" data-dom='plf<"toolbar">trip' data-provide=""  >
        <thead className={handleFloatingHeaderVisibility()}>
          <tr>
            {headers.map((header, thindex) => (
              <th style={{ textAlign: alignHeader(header, thindex) ? 'right' : 'left' }} key={header}> {getHeaderColNames(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.data.map((row, index) => (
            <tr key={index} className={tableRowColors[row.color]}>
              {headers.map((header, tdindex) => (
                <td className={highlightDataCell(header)} style={{ textAlign: alignData(header, row[header]) ? 'right' : 'left' }} key={header}>{/(ach|gth)/.test(header) ? formatNumber(row[header]) : formatToTwoDecimalPlaces(row[header], header, forceDecimal, decimalKeys)}</td>
              ))}
            </tr>
          ))}
        </tbody>

      </table>
    </div>
  );
};

const formatNumber = (numericString) => {

  const number = parseFloat(numericString);
  if (!isNaN(number)) {
    const formatter = new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    var formattedNumber = formatter.format(number);
    const withoutCommas = formattedNumber.replace(/,/g, '');
    return withoutCommas;
  }

  return numericString; // Return original string if conversion fails
};


export default DynamicDT;