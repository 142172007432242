import React, { useEffect, useState } from "react";
import ReportOptions from "../ReportOptions";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { proxy } from '../../process/api';
import DynamicDT from "../DynamicDT";
import DropDownControl from "../DropDownControl";
import BrandedLoader from "../BrandedLoader";
import { generateExcel } from "./../../utils";
import ButtonLoader from "../ButtonLoader";

import ProcessLoader from "../ProcessLoader";


const BL01 = () => {

    const navigate = useNavigate();

    const div_data = JSON.parse(localStorage.getItem('div_data'));
    const year_data = JSON.parse(localStorage.getItem('year_data'));
    const aristoMonths = [4, 5, 6, 7, 8, 9, 10, 11, 1, 2, 3];
    var month_data = JSON.parse(localStorage.getItem('month_data'));
    // const month_data = [
    //     { value: 1, name: "JAN" },
    //     { value: 2, name: "FEB" },
    //     { value: 3, name: "MAR" },
    //     { value: 4, name: "APR" },
    //     { value: 5, name: "MAY" },
    //     { value: 6, name: "JUN" },
    //     { value: 7, name: "JUL" },
    //     { value: 8, name: "AUG" },
    //     { value: 9, name: "SEP" },
    //     { value: 10, name: "OCT" },
    //     { value: 11, name: "NOV" },
    //     { value: 12, name: "DEC" }
    // ];
    const depo_data = JSON.parse(localStorage.getItem('depo_data'));
    const allIndiaTypeDepo = depo_data.data.filter(item => item.value !== "NA"); //removed 0 from all type users

    const updatedDivData = [];

    div_data.data.map((singleDiv, index) => {
        updatedDivData.push(singleDiv);
    });
    //



    const today = new Date();

    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const [SelectedDate, setSelectedDate] = useState(`${day}/${month}/${year}`);

    const [showOptions, setShowOptions] = useState(true);
    const [SelectedDiv, setSelectedDIV] = useState(div_data.data[0].value);
    const [SelectedDepo, setSelectedDepo] = useState(allIndiaTypeDepo[0].value);
    const [SelectedStartMonth, setSelectedStartMonth] = useState(aristoMonths[today.getMonth()]);
    const [SelectedYear, setSelectedYear] = useState(year_data.data[0].value);
    const [selectedDepoName, setSelectedDepoName] = useState(allIndiaTypeDepo[0].name)
    const [SelectecReportType, setSelectecReportType] = useState(1);
    const [entryData, setEntryData] = useState({});
    const [reportGenData, setreportGenData] = useState({});
    const [showProcessLoader, setShowProcessLoader] = useState(false);
    const [loadingEntry, setLoadingEntry] = useState(true);



    const handleBranchChange = (branchVal) => {
        setSelectedDepo(branchVal);
        const depoName = allIndiaTypeDepo.find(location => location.value == branchVal);
        setSelectedDepoName(depoName.name.trim());
    }

    const handleDivChange = (divVal) => {
        setSelectedDIV(divVal);
    }

    const loadEntryData = async (div, depo, myear, month) => {
        const payload = {
            "div_code": div,
            "depo_code": depo,
            "myear": myear,
            "month": month
        };

        const reportAPIResponse = await proxy(`/api/mis/dailyentrylist`, payload, "get");


        if (reportAPIResponse.data.length > 0) {
            setEntryData(reportAPIResponse.data[0]);
        } else {

            setEntryData({
                "budget_per": 0.0,
                "budget": 0.0,
                "cn100": 0,
                "net_sales_today": 0.0,
                "net_sales_upto_date": 0.0,
                "last_month_sales": 0.0,
                "last_year_sales": 0.0,
                "collection_today": 0.0,
                "collection_cumm": 0.0,
                "remittance_today": 0,
                "remittance_cumm": 0.0,
                "outstanding_as_on_date": 0.0,
                "monthly_billing_status": "Open"
            });
        }
        setLoadingEntry(false);


    }

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        if (!isNaN(value) && parseFloat(value) >= 0 && parseFloat(value) <= 99999.99) {
            setEntryData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }


    }

    const handleSubmit = async () => {

        const selectedDate = document.getElementById('SelectedDate').value;
        const [dday, dmonth, dyear] = selectedDate.split('/');
        const formattedDDate = `${dyear}-${dmonth}-${dday}`;

        const today = new Date();
        const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const seconds = String(today.getSeconds()).padStart(2, '0');


        const formattedTime = `${hours}:${minutes}:${seconds}`;

        const aristoToNaturalMonth = [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        const formattedMonthCode = String(aristoToNaturalMonth[SelectedStartMonth - 1]).padStart(2, 0);

        console.log(dmonth);
        console.log(formattedMonthCode);
        if (dmonth != formattedMonthCode) {

            alert("In-valid date selection for selected month");
            return;
        }

        const payload =
        {
            "depo_code": SelectedDepo,
            "division": "A",
            "ddate": formattedDDate,
            "budget_ho": 0.00,
            "budget_br": entryData.budget,
            "sales_trade": entryData.net_sales_upto_date,
            "sales_inst": 0.00,
            "sales_gmsd": 0.00,
            "p_order": 0.00,
            "coll_bud": 0.00,
            "collection": entryData.collection_today,
            "remit": entryData.remittance_today,
            "outstand": entryData.outstanding_as_on_date,
            "status": entryData.monthly_billing_status,
            "remarks": "",
            "ent_date": formattedDate,
            "ent_time": formattedTime,
            "bill_mnth": "Mar",
            "budget_per": entryData.budget_per,
            "sales_today": entryData.net_sales_today,
            "cn100": entryData.cn100,
            "ach": 0.00,
            "surdef": 0.00,
            "last_month": entryData.last_month_sales,
            "last_year": entryData.last_year_sales,
            "collection_cumm": entryData.collection_cumm,
            "remit_cumm": entryData.remittance_cumm,
            "mkt_year": SelectedYear,
            "div_code": SelectedDiv,
            "mnth_code": `${SelectedYear + 1}${formattedMonthCode}`,
            "name": selectedDepoName
        }

        const reportAPIResponse = await proxy(`/api/mis/dailyentry`, payload, "post");


        if (reportAPIResponse && reportAPIResponse.amd_no !== undefined) {
            alert("Data has been updated.");
        } else {
            alert("Invalid response. Please check with provider.")
        }


    }
    useEffect(() => {
        setLoadingEntry(true);
        loadEntryData(SelectedDiv, SelectedDepo, SelectedYear, SelectedStartMonth);


    }, [SelectedDiv, SelectedDepo, SelectedYear, SelectedStartMonth]);

    useEffect(() => {


    }, [entryData]);



    return (<>
        <div className="main-content">
        {showProcessLoader && <ProcessLoader /> }
            {showOptions ?

                <div className="row">
                    <div className="col-12">
                        <div className="card report-card" style={{ width: "max-content" }}>
                            <div className="card-title report-card-title">Daily Entry
                            <button className="btn btn-sm btn-outline btn-md  mr-10 fw-500" onClick={(e) => { navigate("/dashboard") }}>BACK</button>
                            </div>
                            <div className="card-body">



                                <div className="report-option-wrapper">

                                    <div className="report-option-row">
                                        <div className="report-option-item">
                                            <label className="" style={{ minWidth: "auto" }}>Division</label>
                                            <DropDownControl data={updatedDivData} id="report_div_list" name="report_div_list" onChange={(newVal) => { handleDivChange(newVal); }} />
                                        </div>


                                        <div className="report-option-item ml-20">
                                            <label className="mr-16" style={{ minWidth: "auto" }}>Branch</label>
                                            <DropDownControl data={allIndiaTypeDepo} id="report_branch_list" name="report_branch_list"

                                                onChange={handleBranchChange}
                                            />
                                        </div>

                                        <div className="report-option-item ml-20 ">
                                            <label style={{ minWidth: "auto" }}>Mkt&nbsp;Year</label>
                                            <DropDownControl data={year_data.data} id="report_mktyear_list" name="report_mktyear_list" onChange={(newYear) => { setSelectedYear(newYear); }} />

                                        </div>

                                        <div className="report-option-item ml-20">
                                            <label style={{ minWidth: "auto" }}>Billing&nbsp;Month</label>
                                            <DropDownControl data={month_data.data} id="report_startyear_list" name="report_startyear_list" selectedValue={SelectedStartMonth} onChange={(val) => {
                                                setSelectedStartMonth(val);

                                                const aristoToNaturalMonth = [10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9];
                                                console.log(`01/${String(aristoToNaturalMonth[val - 1]).padStart(2, 0)}/${SelectedYear + 1}`);
                                                setSelectedDate(`01/${String(aristoToNaturalMonth[val - 1]).padStart(2, 0)}/${SelectedYear + 1}`);

                                            }} />
                                        </div>
                                        <div className="report-option-item ml-20" style={{ maxWidth: "250px" }}>
                                            <label className="mr-16" style={{ minWidth: "auto" }}>Billing&nbsp;Date</label>
                                            <div class="input-group" data-provide="datepicker" data-orientation="bottom left" data-format="dd/mm/yyyy"  >
                                                <input type="text" class="form-control" id="SelectedDate" value={SelectedDate} />
                                                <div class="input-group-append picker">
                                                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>





                                    {loadingEntry ? <BrandedLoader /> :

                                        <div className="row form-entry-row">
                                            <div className="col-6">
                                                <div className="card-title report-card-title" style={{ borderRadius: "10px 10px 0 0" }}>Billing

                                                </div>
                                                <div className="report-option-row vertical-items" style={{ borderRadius: "0 0 10px 10px" }}>





                                                    <div className=" report-option-item"> <label>1. Target%/Target value </label>
                                                        <input className="form-control allowreset" name="budget_per" key="budget_per" id="budget_per" onChange={handleFieldChange} value={entryData.budget_per} />
                                                        <input className="form-control allowreset" value={entryData.budget} name="budget" key="budget" id="budget" onChange={handleFieldChange} />
                                                    </div>

                                                    <div className=" report-option-item "> <label>2. CN 100% </label>
                                                        <input className="form-control allowreset" value={entryData.cn100} name="cn100" key="cn100" id="cn100" onChange={handleFieldChange} />
                                                    </div>

                                                    <div className="report-option-item"> <label>3. Net Sales Today</label>
                                                        <input className="form-control allowreset" value={entryData.net_sales_today} name="net_sales_today" key="net_sales_today" id="net_sales_today" onChange={handleFieldChange} /></div>


                                                    <div className=" report-option-item"> <label>4. Net Sales Upto Date</label>
                                                        <input className="form-control allowreset" value={entryData.net_sales_upto_date} name="net_sales_upto_date" key="net_sales_upto_date" id="net_sales_upto_date" onChange={handleFieldChange} /></div>



                                                    <div className=" report-option-item"> <label>5. Last Month Sales</label>
                                                        <input className="form-control allowreset" value={entryData.last_month_sales} name="last_month_sales" key="last_month_sales" id="last_month_sales" onChange={handleFieldChange} /></div>
                                                    <div className="report-option-item"> <label>6. Last Year Sales</label>
                                                        <input className="form-control allowreset" value={entryData.last_year_sales} name="last_year_sales" key="last_year_sales" id="last_year_sales" onChange={handleFieldChange} /></div>
                                                </div>

                                            </div>


                                            <div className="col-6">
                                                <div className="card-title report-card-title" style={{ borderRadius: "10px 10px 0 0" }}>Collection

                                                </div>
                                                <div className="report-option-row vertical-items" style={{ borderRadius: "0 0 10px 10px" }}>


                                                    <div className=" report-option-item"> <label>1. Collection Today</label>
                                                        <input className="form-control allowreset" value={entryData.collection_today} name="collection_today" key="collection_today" id="collection_today" onChange={handleFieldChange} /></div>
                                                    <div className=" report-option-item"> <label>2. Collection Cumm.</label>
                                                        <input className="form-control allowreset" value={entryData.collection_cumm} name="collection_cumm" key="collection_cumm" id="collection_cumm" onChange={handleFieldChange} /></div>
                                                    <div className=" report-option-item"> <label>3. Remittance Today.</label>
                                                        <input className="form-control allowreset" value={entryData.remittance_today} name="remittance_today" key="remittance_today" id="remittance_today" onChange={handleFieldChange} /></div>

                                                    <div className="report-option-item"> <label>4. Remittance Cumm.</label>
                                                        <input className="form-control allowreset" value={entryData.remittance_cumm} name="remittance_cumm" key="remittance_cumm" id="remittance_cumm" onChange={handleFieldChange} /></div>

                                                    <div className=" report-option-item"> <label>5. Outstanding As On Date.</label>
                                                        <input className="form-control allowreset" value={entryData.outstanding_as_on_date} name="outstanding_as_on_date" key="outstanding_as_on_date" id="outstanding_as_on_date" onChange={handleFieldChange} /></div>
                                                    <div className=" report-option-item"> <label>6. Monthly Billing Status.</label>

                                                        <DropDownControl
                                                            data={[{ "value": "Open", "name": "Open" }, { "value": "Close", "name": "Close" }]}
                                                            selectedValue={entryData.monthly_billing_status}
                                                            onChange={(val) => {
                                                                setEntryData(prevState => ({
                                                                    ...prevState,
                                                                    ['monthly_billing_status']: val
                                                                }));
                                                            }}
                                                            id="report_branch_list" name="report_branch_list"


                                                        /></div>
                                                </div></div>

                                        </div>
                                    }













                                    <div className="report-option-action-row float-right">
                                        <button onClick={(e) => { const inputs = document.querySelectorAll('.allowreset'); inputs.forEach(input => { input.value = '0'; }); }} className="btn btn-outline btn-md btn-sec mr-10 fw-500" >RESET</button>
                                        <button onClick={handleSubmit} className="btn btn-md btn-primary pr-30 pl-30 fw-500 " id="btnReportGen"  >SUBMIT

                                        </button>




                                    </div>

                                </div>
                            </div>  </div>  </div>  </div>

                :
                <></>
            }
        </div>

    </>);
}

export default BL01;