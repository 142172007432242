import React from 'react';

const CardStatsPanel = ({heading, monthly_amount, comm_amount ,type, bg_color, symbol}) => {
return (
    <div class="col-md-2">
                <div className={`card card-data-bottom  ${type}`} style={{ backgroundColor:bg_color }}>
                  <div className={`card-content card-stats`} >

                 

<div className={`card-data ${parseFloat(monthly_amount) > 0 ? 'positive-number' : 'negative-number'}`}>
  {formatNumber(monthly_amount)}{symbol} <span className='card-data-type'>Mth</span></div>
                    <div className={`card-data ${parseFloat(comm_amount) > 0 ? 'positive-number' : 'negative-number'}`}>
                      {formatNumber(comm_amount)}{symbol} <span className='card-data-type'>Cum</span></div>


                    <div className={`card-heading`}>{heading}</div>
                   
                   
                  </div>
                
                </div>
             </div>
);
}

const formatNumber = (numericString) => {
   
    const number = parseFloat(numericString);
    if (!isNaN(number)) {
      const formatter = new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2, 
      });
      
      var formattedNumber = formatter.format(number);
      const withoutCommas = formattedNumber.replace(/,/g, '');
  
      return withoutCommas;
    }
  
    return numericString; // Return original string if conversion fails
  };
  

export default CardStatsPanel;
