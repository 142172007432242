import React, { useEffect, useState } from "react";
import ReportOptions from "../ReportOptions";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { proxy } from '../../process/api';
import DynamicDTFloat from "../DynamicDTFloat";
import DropDownControl from "../DropDownControl";
import BrandedLoader from "../BrandedLoader";
import {generateExcel,getHeaderColNames,excelColorCodes,transformNavDataForLabels} from "./../../utils";
import ButtonLoader from "../ButtonLoader";
import ProcessLoader from "../ProcessLoader";


const BI03 = () => {
    const navigate = useNavigate();

    const div_data = JSON.parse(localStorage.getItem('div_data'));
    const year_data = JSON.parse(localStorage.getItem('year_data'));
    const month_data = JSON.parse(localStorage.getItem('month_data'));
    const depo_data = JSON.parse(localStorage.getItem('depo_data'));
    const allIndiaTypeDepo = depo_data.data.filter(item => item.value !== "NA"); //removed 0 from all type users

    const [showOptions, setShowOptions] = useState(true);
    const [SelectedDiv, setSelectedDIV] = useState(div_data.data[0].value);
    const [SelectedDepo, setSelectedDepo] = useState(allIndiaTypeDepo[0].value);
    const [SelectedYear, setSelectedYear] = useState(year_data.data[0].value);
    const [SelectedStartMonth, setSelectedStartMonth] = useState(month_data.data[0].value);
    const [SelectedEndMonth, setSelectedEndMonth] = useState(month_data.data[month_data.index - 1].value);
   
    const [SelectecReportType, setSelectecReportType] = useState(1);
    const [showReportLoader, setShowReportLoader] = useState(false);
    
    const [reportGenData, setreportGenData] = useState({});
    const [showProcessLoader, setShowProcessLoader] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
   
    const handleExportSheet = async () =>{

        var reportJson = {};
        reportJson.fileName = reportGenData.title+".xlsx";
        reportJson.title = reportGenData.title;
        reportJson.author = "Aristo Pharma";
        reportJson.sheets = [
            {
                name: "Sheet 1",
                data: []
            }
        ]
        
    const columns = Object.keys(reportGenData.data[0]).filter(key => key !== 'color');
    const headerFormatted = columns.map(key => getHeaderColNames(key));
    
    reportJson.sheets[0].data.push({
        backgroundColor: "#0043a9", 
        fontColor: "#FFFFFF",
        fontSize: 13,
        bold: true,
        values: headerFormatted
    });

    const backgroundColors = excelColorCodes;
    reportGenData.data.forEach(item => {
        const row = {
            backgroundColor: backgroundColors[item.color] || "", // Default to white if undefined
            fontColor: item.color === 2 ? "#FFFFFF" : "#333333",
            fontSize: 12,
            bold: false,
            values: columns.map(col => item[col])
        };
        reportJson.sheets[0].data.push(row);
    });

   

   
   
          
        generateExcel(reportJson).catch(console.error);

    } 


   

    const handleReportGen = async () => {
        setShowReportLoader(true);
        document.getElementById('btnReportGen').innerHTML = "PROCESSING...";  

    

        const payload = {
            "myear": SelectedYear,
            "div_code": SelectedDiv,
            "depo_code": SelectedDepo,
            "smon": SelectedStartMonth,
            "emon": SelectedEndMonth,
            "rep_type": SelectecReportType,
          
        };

        var reportAPIResponse = {};
        reportAPIResponse = await proxy(`/api/mis/mktrepo7`, payload, "get");
        setreportGenData(reportAPIResponse);
        setShowOptions(false);
        setShowProcessLoader(false);
    }

    const handleBranchChange = (branchVal) => {
        setSelectedDepo(branchVal);
      

    }

    const handleDivChange = (divVal) => {
        setSelectedDIV(divVal);
      
    }

    useEffect(() => {
        transformNavDataForLabels("BI03").then(title => {
            setPageTitle(title); 
        });
      
    }, []);
    return (<>
        <div class="main-content">
        {showProcessLoader && <ProcessLoader /> }
            {showOptions ?
               <ReportOptions title={pageTitle} >

                    <div className="report-option-wrapper">

                        <div className="report-option-row">
                            <div className="report-option-item">
                                <label>Division</label>
                                <DropDownControl data={div_data.data} id="report_div_list" name="report_div_list" onChange={(newVal) => { handleDivChange(newVal); }} />
                            </div>

                            <div className="report-option-item ml-20">
                                <label className="mr-16">Branch</label>
                                <DropDownControl data={allIndiaTypeDepo} id="report_branch_list" name="report_branch_list"

                                    onChange={handleBranchChange}
                                />
                            </div>


                        </div>
                        <div className="report-option-row">
                            <div className="report-option-item ">
                                <label>Mkt&nbsp;Year</label>
                                <DropDownControl data={year_data.data}
                                    id="report_mktyear_list" name="report_mktyear_list"
                                    onChange={(newYear) => {
                                        setSelectedYear(newYear);
                                    
                                    }} />

                            </div>

                            <div className="report-option-item ml-20">
                                <label>From</label>
                                <DropDownControl data={month_data.data} id="report_startyear_list" name="report_startyear_list" selectedValue={SelectedStartMonth} onChange={(val) => { setSelectedStartMonth(val) }} />
                            </div>
                            <div className="report-option-item ml-10">
                                <label>To</label>
                                <DropDownControl data={month_data.data} id="report_endyear_list" name="report_endyear_list" selectedValue={SelectedEndMonth} onChange={(val) => { setSelectedEndMonth(val) }} />
                            </div>

                        </div>
                        <div className="report-option-row">

                        <div className="report-option-item ">
                                <input type="radio" name="bi05_type" className="mr-2" checked={SelectecReportType == 1} onChange={(val) => { setSelectecReportType(1) }} />
                                <label>H.Q.</label>


                            </div>


                            <div className="report-option-item ">
                                <input type="radio" name="bi05_type" className="mr-2" checked={SelectecReportType == 2} onChange={(val) => { setSelectecReportType(2) }} />
                                <label>Region</label>

                            </div>

                            <div className="report-option-item ml-20">
                                <input type="radio" name="bi05_type" className="mr-2" checked={SelectecReportType == 3} onChange={(val) => { setSelectecReportType(3) }} />
                                <label>Area </label>


                            </div>
                            <div className="report-option-item ml-20">
                                <input type="radio" name="bi05_type" className="mr-2" checked={SelectecReportType == 4} onChange={(val) => { setSelectecReportType(4) }} />
                                <label>Branch</label>


                            </div>

                           

                        </div>




                        <div className="report-option-action-row">
                        
                            <button onClick={(e) => { navigate('/dashboard'); }} className="btn btn-outline btn-md btn-sec mr-10 fw-500">BACK</button>
                            <button onClick={handleReportGen} className="btn btn-md btn-primary pr-30 pl-30 fw-500 " id="btnReportGen" >SUBMIT
                            {showReportLoader &&  <div style={{marginRight:"auto"}}><ButtonLoader/></div> }
                            </button>
                        </div>
                    </div>

                </ReportOptions>
                :
                <><div className="row">
                    <div className="col-12">
                        <div className="card report-card" style={{ width: "auto", minWidth: "100%" }}>
                            <div className="card-title report-card-title">{reportGenData.title}
                                <button className="btn btn-outline btn-md  mr-10 fw-500" onClick={(e) => { window.location.reload() }}>BACK</button>
                            </div>
                            <div className="card-body">
                                <DynamicDTFloat
                                    tableData={reportGenData}
                                    firstColWidth="220px"
                                    secColWidth="100px"
                                    handleExportSheet={handleExportSheet}
                                    fixedColumns={true}
                                />

                            </div>
                            <div className="card-footer">


                            </div>
                        </div>
                    </div>
                </div></>
            }

        </div>

    </>);
}

export default BI03;