import React, { useEffect, useRef, useState } from 'react';
import DashboardTopPanel from './DashboardTopPanel';
import CardList from './CardList';
import { proxy } from './../process/api';
import { BeatLoader } from 'react-spinners';
import { getUserType } from '../utils';

import DashChart from './DashChart';
const Dashboard = () => {

    const [ACHGroupData, setACHGroupData] = useState({});
    const [isACHGroupLoading, setACHGroupLoading] = useState(true);

    const [PendingPIData, setPendingPIData] = useState({});
    const [isPendingPILoading, setPendingPILoading] = useState(true);

    const [TopFiveStockist, setTopFiveStockist] = useState({});
    const [isTopFiveStockistLoading, setisTopFiveStockistLoading] = useState(true);

    const [TheraputicData, setTheraputicData] = useState({});
    const [isTheraputicDataLoading, setTheraputicDataLoading] = useState(true);
    const [lastUpdate, setLastUpdate] = useState(["", "", "", ""]);

    const selected_depo_name = (JSON.parse(localStorage.getItem('depo_data')).data.find(branch => branch.value ==  localStorage.getItem('selected_depo') ).name);
    const selected_div_name = (JSON.parse(localStorage.getItem('div_data')).data.find(div => div.value == localStorage.getItem('selected_div'))).name;
    const selected_month_name = (JSON.parse(localStorage.getItem('month_data')).data.find(month => month.value == localStorage.getItem('selected_month') )).name;

    const selected_year_name = (JSON.parse(localStorage.getItem('year_data')).data.find(month => month.value == localStorage.getItem('selected_year') )).name;
  
    const selected_param_data = selected_div_name+" "+selected_depo_name+" "+selected_month_name+" "+selected_year_name;
    const getACHGroupData = async () => {

        const selected_year = localStorage.getItem('selected_year');
        const selected_month = localStorage.getItem('selected_month');
        const selected_div = localStorage.getItem('selected_div');
        const selected_depo = localStorage.getItem('selected_depo');

        const apiResponse = await proxy(`/api/mis/dashboardgroupwiseach/${selected_year}/${selected_div}/${selected_depo}/${selected_month}`, {}, "get", true);
        if (apiResponse.title) {
            setACHGroupLoading(false);
            setACHGroupData(apiResponse);
        }
    }

    const loadLastUpdateMsg = async () => {
        const dataAPIResponse = await proxy(`/api/mis/loginmessage/`, {}, "get", true);

        console.log(dataAPIResponse);
        if (dataAPIResponse != '') {
            var msgParts = String(dataAPIResponse.message).split("|");
            setLastUpdate(msgParts);
        }

    }




    const getPIData = async () => {

        const selected_year = localStorage.getItem('selected_year');
        const selected_month = localStorage.getItem('selected_month');
        const selected_div = localStorage.getItem('selected_div');
        const selected_depo = localStorage.getItem('selected_depo');
        var endpoint = "";
        if (getUserType() === 3) {
            endpoint = "dashboardtop5products";
        } else {
            endpoint = "dashboardpendingpi";
        }
        const apiResponse = await proxy(`/api/mis/${endpoint}/${selected_year}/${selected_div}/${selected_depo}/${selected_month}`, {}, "get", true);
        if (apiResponse.title) {
            setPendingPILoading(false);
            setPendingPIData(apiResponse);
        }
    }

    const getTopFiveStockist = async () => {

        const selected_year = localStorage.getItem('selected_year');
        const selected_month = localStorage.getItem('selected_month');
        const selected_div = localStorage.getItem('selected_div');
        const selected_depo = localStorage.getItem('selected_depo');
        var endpoint = "";
        if (getUserType() === 2 || getUserType() === 3) {


            if (selected_depo == 0) {
                endpoint = "dashboardbranchwiseach";
            } else {
                endpoint = "dashboardHqwiseach";
            }
        } else {
            endpoint = "dashboardtop5stockiest";
        }
        const apiResponse = await proxy(`/api/mis/${endpoint}/${selected_year}/${selected_div}/${selected_depo}/${selected_month}`, {}, "get", true);
        if (apiResponse.title) {
            setisTopFiveStockistLoading(false);
            setTopFiveStockist(apiResponse);
        }
    }

    const getTheraputicData = async () => {

        const selected_year = localStorage.getItem('selected_year');
        const selected_month = localStorage.getItem('selected_month');
        const selected_div = localStorage.getItem('selected_div');
        const selected_depo = localStorage.getItem('selected_depo');
        var endpoint = "";
        if (getUserType() === 1 || getUserType() === 4 || getUserType() === 5) {
            endpoint = "dashboardHqwiseach";
        } else {
            endpoint = "dashboardthept";
        }
        const apiResponse = await proxy(`/api/mis/${endpoint}/${selected_year}/${selected_div}/${selected_depo}/${selected_month}`, {}, "get", true);
        if (apiResponse.title) {
            setTheraputicDataLoading(false);
            setTheraputicData(apiResponse);
        }
    }

    useEffect(() => {
        getACHGroupData();
        getPIData();
        getTopFiveStockist();
        getTheraputicData();
        loadLastUpdateMsg();
    }, []);


    return (

        <div class="main-content">

            <div class="row">

                <div class="col-12">

                    <span class="dash_top_message">{lastUpdate[0]}. {lastUpdate[1]}. <span style={{marginLeft:"10px", color:"#1840a9"}}>Data For - {selected_param_data}</span></span>
                </div>



                <DashboardTopPanel
                />

            </div>
            <div className={`row`}>
                <div class='col-md-8 col-xs-12' >

                    <div class="card chart-card">
                        <div class="card-content">

                            <DashChart />
                        </div>

                    </div>
                </div>

                <div class='col-md-4 col-xs-12' >

                    {isACHGroupLoading ?
                        <div className={`card card-datac`}>
                            <div className={`card-content `} >
                                <BeatLoader color="#1940a9" />
                            </div>
                        </div>
                        : <CardList
                            title={ACHGroupData.title}
                            height="295"
                            list_values={ACHGroupData.data}
                            height_type="dynamic"
                        />}


                </div>

                <div class='col-md-4 col-xs-12' >
                    {isPendingPILoading ?
                        <div className={`card card-datac`}>
                            <div className={`card-content `} >
                                <BeatLoader color="#1940a9" />
                            </div>
                        </div>
                        : <CardList
                            title={PendingPIData.title}
                            list_values={PendingPIData.data}
                            height_type="fixed"

                        />}


                </div>
                <div class='col-md-4 col-xs-12' >


                    {isTopFiveStockistLoading ?
                        <div className={`card card-datac`}>
                            <div className={`card-content `} >
                                <BeatLoader color="#1940a9" />
                            </div>
                        </div>
                        :

                        <CardList
                            title={TopFiveStockist.title}
                            list_values={TopFiveStockist.data}
                            height_type="fixed"


                        />
                    }

                </div>

                <div class='col-md-4 col-xs-12' >
                    {isTheraputicDataLoading ?
                        <div className={`card card-datac`}>
                            <div className={`card-content `} >
                                <BeatLoader color="#1940a9" />
                            </div>
                        </div>
                        :
                        <CardList
                            title={TheraputicData.title}
                            list_values={TheraputicData.data}
                            height_type="fixed"
                        />}
                </div>
            </div>

        </div>


    );
};

export default Dashboard;
