import {useState,React,useEffect} from 'react';

const CardList = ({ title, list_values, height, height_type }) => {

  var boxHeight = "180px";
  if(height){
     boxHeight = height+"px";
  }

  const [cardListHeight, setCardHeight] = useState({boxHeight});

  
  
  useEffect(() => {
   
    const mainContentElement = document.querySelector('.main-content');
    
    if (mainContentElement) {
      const mainContentHeight = mainContentElement.offsetHeight -10; 
      const newChartHeight = Math.max(mainContentHeight - 380, 0)+ 18; 

      if( height_type == "dynamic"){
        setCardHeight(`${newChartHeight}px`); 

      }
     
      
      
     
    }
  }, []); 


  return (
    <div className="card card-list" style={{height:`${cardListHeight}`}}>
      <div className="card-title card-title-blue">{title}</div>
      {list_values && list_values.length > 0 ? (
        <div className="media-list media-list-hover media-list-divided scrollable">
          {list_values.map((item, index) => (
            <div key={index} className="media media-single">
              <span className="title">{item.name}</span>
              <span>{formatNumber(item.value)}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className="media-list-placeholder" style={{padding:`10px`}}>No items to display</div>
      )}
    </div>
  );
};


const formatNumber = (numericString) => {
   
  const number = parseFloat(numericString);
  if (!isNaN(number)) {
    const formatter = new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2, 
    });
    
    var formattedNumber = formatter.format(number);
    const withoutCommas = formattedNumber.replace(/,/g, '');

    return withoutCommas;
  }

  return numericString; // Return original string if conversion fails
};



export default CardList;
