import React, {useEffect, useState} from "react";
import { HashLoader } from "react-spinners";

const ProcessLoader = ({isVisible = true})=>{


    return(

        <>
            <div style={{display:isVisible ? "block" : "none"}} id="pageProcessLoader">
                <HashLoader  size={80} color="#3663de" />
            </div>
        </>
    );

}

export default ProcessLoader;