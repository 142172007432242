import React, { useEffect, useState } from "react";
import ReportOptions from "../ReportOptions";
import { Button } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { proxy } from '../../process/api';
import DynamicDTFloat from "../DynamicDTFloat";
import DynamicDTFloatTabs from "../DynamicDTFloatTabs";
import DropDownControl from "../DropDownControl";
import BrandedLoader from "../BrandedLoader";
import { nw04_report_json_format, generateExcel, getHeaderColNames,transformNavDataForLabels , excelColorCodes,   goFullScreen} from "../../utils";
import MultiSelectOptions from "./../MultiSelectOptions";
import MultiSheetProcessingLoader from "../MultiSheetProcessingLoader"; 
import ProcessLoader from "../ProcessLoader";

const NW06 = () => {

    const navigate = useNavigate();

    const div_data = JSON.parse(localStorage.getItem('div_data'));
    const year_data = JSON.parse(localStorage.getItem('year_data'));
    const month_data = JSON.parse(localStorage.getItem('month_data'));
    const depo_data = JSON.parse(localStorage.getItem('depo_data'));

    const [showOptions, setShowOptions] = useState(true);
    const [reportGenData, setreportGenData] = useState({});
    const [SelectedDiv, setSelectedDIV] = useState(div_data.data[0].value);
    const [SelectedYear, setSelectedYear] = useState(year_data.data[0].value);
    const [SelectedDepo, setSelectedDepo] = useState(depo_data.data[0].value);
    const [SelectedStartMonth, setSelectedStartMonth] = useState(month_data.data[0].value);
    const [SelectedEndMonth, setSelectedEndMonth] = useState(month_data.data[month_data.index - 1].value);
    const [UnitValue, setUnitvalue] = useState(2);
    const [ReportType, setReportType] = useState(2);
    const [ReportOption, setReportOption] = useState(1);
    const [groupLoader, setGroupLoader] = useState(true);
    const [GroupListItems, setGroupListItems] = useState([]);
    const [SelectedGroup, setSelectedGroup] = useState(0);
    const [selectedPackCheck, setSelectedpackCheck] = useState(0);
    const [productListItems, setProductListItems] = useState([]);
    const [productlistLoading, setProductlistLoading] = useState(true);
    const [SelectedProductCodes, setSelectedProductCodes] = useState([]);
    const [tabLoader, setTabLoader] = useState([]); //add product codes
    const [productReportData, setProductReportdata] = useState([]);
    const [sheetloaderMessage, setSheetLoaderMessage] = useState("");
    const [pageTitle, setPageTitle] = useState('');


    const [reportGenerationCompleted, setReportGenerationCompleted] = useState(false);
    const [reportDataReady, setReportDataReady] = useState(false);
    const [MultiSheetDownloadStart, setMultiSheetDownloadStart] = useState(false);
    const [showProcessLoader, setShowProcessLoader] = useState(false);

    const handleExportSheet = async () => {

        var reportJson = {};
        reportJson.fileName = reportGenData.title + ".xlsx";
        reportJson.title = reportGenData.title;
        reportJson.author = "Aristo Pharma";

        reportJson.sheets = [
            {
                name: "Sheet 1",
                data: []
            }
        ]

        const columns = Object.keys(reportGenData.data[0]).filter(key => key !== 'color');
        const headerFormatted = columns.map(key => getHeaderColNames(key));

        reportJson.sheets[0].data.push({
            backgroundColor: "#0043a9",
            fontColor: "#FFFFFF",
            fontSize: 13,
            bold: true,
            values: headerFormatted
        });

        const backgroundColors = excelColorCodes;

        reportGenData.data.forEach(item => {
            const row = {
                backgroundColor: backgroundColors[item.color] || "",
                fontColor: item.color === 2 ? "#FFFFFF" : "#333333",
                fontSize: 12,
                bold: false,
                values: columns.map(col => item[col])
            };
            reportJson.sheets[0].data.push(row);
        });

        generateExcel(reportJson).catch(console.error);

    }

    const handleReportGen = async () => {
        goFullScreen();

        setShowProcessLoader(true);
       

        var groupCode = 0;

        if (ReportType == 1) {
            groupCode = SelectedGroup;
        }

        
        if (selectedPackCheck == 1) {
            setShowProcessLoader(true);
            

            if (SelectedProductCodes.length == 0) {
                alert('Select aleast one product');
                return;
            }
            document.getElementById('btnReportGen').innerHTML = "PROCESSING...";
            generateProductReport(SelectedProductCodes[0].value);
            setShowOptions(false);
            setShowProcessLoader(false);
        } else {
            document.getElementById('btnReportGen').innerHTML = "PROCESSING...";
            const payload = {
                "myear": parseInt(SelectedYear),
                "div_code": parseInt(SelectedDiv),
                "depo_code": parseInt(SelectedDepo),
                "smon": parseInt(SelectedStartMonth),
                "emon": parseInt(SelectedEndMonth),
                "gp_code": parseInt(groupCode),
                "option": ReportOption,
                "pack_check_box": 0,
                "uv":UnitValue

            }

            var reportAPIResponse = {};
            reportAPIResponse = await proxy(`/api/mis/mktrepo5`, payload, "get");

            setreportGenData(reportAPIResponse);
            setShowOptions(false);
            setShowProcessLoader(false);
        }


    }

    const generateProductReport = async (productCode) => {
        const payload = {
            "myear": parseInt(SelectedYear),
            "div_code": parseInt(SelectedDiv),
            "depo_code": parseInt(SelectedDepo),
            "smon": parseInt(SelectedStartMonth),
            "emon": parseInt(SelectedEndMonth),
            "gp_code": parseInt(productCode),
            "option": ReportOption,
            "pack_check_box": 1,
            "uv":UnitValue

        }


        var reportAPIResponse = {};
        reportAPIResponse = await proxy(`/api/mis/mktrepo5`, payload, "get", false);
        console.log(reportAPIResponse);

        var processedReportData = [];

        if (reportAPIResponse == '') {


            processedReportData = { title: "NO DATA", data: [] };

        } else {

            processedReportData = reportAPIResponse;
        }


        const newProductReport = { "product_code": productCode, "data": processedReportData };
        setProductReportdata((productReportData) => {
            const index = productReportData.findIndex(p => p.product_code === newProductReport.product_code);
            if (index > -1) {
                return productReportData.map((p, i) => i === index ? newProductReport : p);
            } else {

                return [...productReportData, newProductReport];
            }
        });
        setTabLoader([...tabLoader, productCode]);
      


    }



    const loadGroupList = async (div, year) => {

        setGroupLoader(true);
        const grouplistAPIResponse = await proxy(`/api/mis/grouplist/${div}/${year}`, {}, "get");
        setGroupListItems(grouplistAPIResponse);
        setSelectedGroup(grouplistAPIResponse.data[0].value)
        setGroupLoader(false);


    }
    function convertProductData(dataAPIResponse) {
        if (dataAPIResponse && Array.isArray(dataAPIResponse.data)) {
            const converted_list = dataAPIResponse.data.map(item => ({
                value: item['value'],   
                label: item['name']
            }));
            return converted_list;
        }
        return [];
    }


    const handleProductChange = (selectedProducts) => {
        if (selectedProducts.length > 20) {
            alert('Maximum of 20 products can be selected at a time. Please remove extra products.');
        } else {
            setSelectedProductCodes(selectedProducts);
        }
    }

    function getTabreport(pcode) {

        const index = productReportData.findIndex(item => item.product_code === pcode);
        return productReportData[index].data;

    }

    useEffect(() => {

        if (reportGenerationCompleted) {
            setReportDataReady(true);
        }
    }, [reportGenerationCompleted]);



    useEffect(() => {
        if (reportDataReady) {
            var multiReportTitle = [];

            var reportJson = {};
            reportJson.fileName =pageTitle+ "-" + SelectedYear + ".xlsx";
            reportJson.title = "Rupeewise Gross/Credit/Net Sale";
            reportJson.author = "Aristo Pharma";


            reportJson.sheets = [];
            productReportData.map((singlereport, index) => {


                //
                reportJson.sheets.push({
                    name: SelectedProductCodes[index].value.toString().trim(),
                    data: []
                })
                multiReportTitle.push(singlereport.data.title);

                if (singlereport.data.data.length > 0) {

                    const columns = Object.keys(singlereport.data.data[0]).filter(key => key !== 'color');
                    const headerFormatted = columns.map(key => getHeaderColNames(key));



                    reportJson.sheets[index].data.push({
                        backgroundColor: "#0043a9",
                        fontColor: "#FFFFFF",
                        fontSize: 13,
                        bold: true,
                        values: headerFormatted
                    });

                    const backgroundColors = excelColorCodes;
                    singlereport.data.data.forEach(item => {
                        const row = {
                            backgroundColor: backgroundColors[item.color] || "",
                            fontColor: item.color === 2 ? "#FFFFFF" : "#333333",
                            fontSize: 12,
                            bold: false,
                            values: columns.map(col => item[col])
                        };
                        reportJson.sheets[index].data.push(row);
                    });

                } else {

                    const columns = ['NoData'];
                    const item = { "NoData": "No Data" }
                    const headerFormatted = ["No Data"];
                    reportJson.sheets[index].data.push({
                        backgroundColor: "#0043a9",
                        fontColor: "#FFFFFF",
                        fontSize: 13,
                        bold: true,
                        values: headerFormatted
                    });
                    const row = {
                        backgroundColor: "",
                        fontColor: "#333333",
                        fontSize: 12,
                        bold: false,
                        values: columns.map(col => item[col])
                    };
                    reportJson.sheets[index].data.push(row);

                }
            });
 
            generateExcel(reportJson, 40, 20, 0, 3, multiReportTitle).catch(console.error);

            setReportDataReady(false);
            setMultiSheetDownloadStart(false);
            setReportGenerationCompleted(false);
        }
    }, [reportDataReady]);

    const handleTabChange = (productCode) => {
        if (!tabLoader.includes(productCode)) {
            generateProductReport(productCode);
        } else {

        }
    }

    const handleExportMultiSheet = async () => {

        setMultiSheetDownloadStart(true);


        if (SelectedProductCodes.length !== productReportData.length) {
            for (let i = 0; i < SelectedProductCodes.length; i++) {
                setSheetLoaderMessage("Processing data for product #" + SelectedProductCodes[i].value);
                await generateProductReport(SelectedProductCodes[i].value);
            }
            setReportGenerationCompleted(true);
        } else {
            setReportGenerationCompleted(true);
        }


    }

    const loadProductItems = async (division, year, group) => {

        setProductlistLoading(true);
        const payload = {};
        const dataAPIResponse = await proxy(`/api/mis/productlist/${division}/${year}/${group}`, payload, "get", true);
        const converted_list = convertProductData(dataAPIResponse);
        setProductListItems(converted_list);
        setProductlistLoading(false);

        setSelectedProductCodes(converted_list);
    }


    const handleBranchChange = async (newValue) => {
        setSelectedDepo(newValue);
        if (newValue != 0) {
            setReportOption(0);
        } else {
            setReportOption(1)
        }

    }

    

    useEffect(() => {
        loadGroupList(SelectedDiv, SelectedYear);
        transformNavDataForLabels("NW06").then(title => {
            setPageTitle(title); 
        });

    }, []);

    return (
        <>
            <div class="main-content">
            {showProcessLoader && <ProcessLoader /> }
                {showOptions ?
                    <ReportOptions title={pageTitle} >
                        <div className="report-option-wrapper">
                            <div className="report-option-row">
                                <div className="report-option-item">
                                    <label>Division</label>
                                    <DropDownControl data={div_data.data} id="report_div_list" name="report_div_list" onChange={(newVal) => { setSelectedDIV(newVal); loadGroupList(newVal, SelectedYear); }} />
                                </div>

                                <div className="report-option-item ml-20">
                                    <label>Branch</label>
                                    <DropDownControl data={depo_data.data} id="report_branch_list" name="report_branch_list"

                                        onChange={(newBranch) => { handleBranchChange(newBranch); }}
                                    />
                                </div>

                            </div>



                            <div className="report-option-row">
                                <div className="report-option-item ">
                                    <label>Mkt&nbsp;Year</label>
                                    <DropDownControl data={year_data.data} id="report_mktyear_list" name="report_mktyear_list" onChange={(newYear) => { console.log(newYear); setSelectedYear(newYear) }} />

                                </div>

                                <div className="report-option-item ml-10">
                                    <label>From</label>
                                    <DropDownControl data={month_data.data} id="report_startyear_list" name="report_startyear_list" selectedValue={SelectedStartMonth} onChange={(val) => { console.log(val); setSelectedStartMonth(val) }} />
                                </div>
                                <div className="report-option-item ml-10">
                                    <label>To</label>
                                    <DropDownControl data={month_data.data} id="report_endyear_list" name="report_endyear_list" selectedValue={SelectedEndMonth} onChange={(val) => { console.log(val); setSelectedEndMonth(val) }} />
                                </div>

                            </div>


                            <div className="report-option-row">

                                <div className="report-option-item ml-10">
                                    <input type="radio" class="js-switched mr-10" name="report_option_branchhq" checked={(true && ReportOption === 1)}
                                        onChange={(e) => { setReportOption(1); }} disabled={SelectedDepo == 0 ? false : true} />
                                    <label>Branchwise</label>
                                </div>
                                <div className="report-option-item">
                                    <input type="radio" class="js-switched mr-10" name="report_option_branchhq" checked={(true && ReportOption === 2)}
                                        onChange={(e) => { setReportOption(2); }} disabled={SelectedDepo == 0 ? false : true} />
                                    <label>HQwise</label>
                                </div>


                            </div>
                            <div className="report-option-row">
                                <div className="report-option-item ml-10">
                                    <input type="radio" class="js-switched mr-10" name="report_branchhq" checked={(true && ReportType === 2)}
                                        onChange={(e) => { setReportType(2); }} />
                                    <label>All</label>
                                </div>
                                <div className="report-option-item ml-30">
                                    <input type="radio" class="js-switched mr-10" name="report_branchhq" checked={(true && ReportType === 1)}
                                        onChange={(e) => { setReportType(1); }} />
                                    <label>Groupwise</label>
                                </div>

                            </div>





                            {ReportType == 1 &&
                                <>
                                    <div className="report-option-row">
                                        <div className="report-option-item">
                                            <label>Group</label>

                                            {groupLoader ? <BrandedLoader /> :
                                                <DropDownControl
                                                    data={GroupListItems.data}
                                                    id="report_branch_list"
                                                    name="report_branch_list"

                                                    onChange={(optionValue) => { setSelectedGroup(optionValue); if (selectedPackCheck) { loadProductItems(SelectedDiv, SelectedYear, optionValue) } }}
                                                />
                                            }
                                        </div>

                                        <div className="report-option-item ml-20">


                                            <input type="checkbox" className="form-control" checked={selectedPackCheck} onChange={() => { setSelectedpackCheck(!selectedPackCheck); if(selectedPackCheck){setUnitvalue(2)} if (!selectedPackCheck) { setUnitvalue(1); loadProductItems(SelectedDiv, SelectedYear, SelectedGroup) } }} /> &nbsp;&nbsp;&nbsp; <label className="">Pack </label>
                                        </div>

                                        <div className="report-option-item ml-20">
                                    <input type="radio" disabled={!selectedPackCheck} class="js-switched mr-10" name="report_uv" checked={(true && UnitValue === 1)}
                                        onChange={(e) => { setUnitvalue(1); }} />
                                    <label>Unit</label>
                                </div>
                                <div className="report-option-item ml-10">
                                    <input type="radio" class="js-switched mr-10"  name="report_uv" checked={(true && UnitValue === 2)}
                                        onChange={(e) => { setUnitvalue(2); }} />
                                    <label>Value</label>
                                </div>



                                    </div>

                                    {selectedPackCheck == 1 &&
                                        <div className="report-option-row">
                                            <div className="report-option-item">
                                                <label >Select&nbsp;Product</label>

                                                {productlistLoading ? <BrandedLoader /> :
                                                    <MultiSelectOptions
                                                        itemsData={productListItems}
                                                        onChange={handleProductChange}
                                                        defValue={productListItems}

                                                    />}
                                            </div>
                                        </div>}</>


                            }



                            <div className="report-option-action-row">
                                <button onClick={(e) => { navigate('/dashboard'); }} className="btn btn-outline btn-md btn-sec mr-10 fw-500">BACK</button>
                                <button onClick={handleReportGen} className="btn btn-md btn-primary pr-30 pl-30 fw-500 " id="btnReportGen" >SUBMIT</button>
                            </div>
                        </div>
                    </ReportOptions>
                    :
                    <>
                        {selectedPackCheck == 1 ?
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card report-card" style={{ width: "auto" }}>
                                            <div className="card-title report-card-title">{pageTitle}
                                                <button className="btn btn-outline btn-md  mr-10 fw-500" onClick={(e) => { window.location.reload() }}>BACK</button>
                                            </div>
                                            <div className="card-body card-body-multireport">


                                                <ul class="nav nav-tabs nav-tabs-light-mode nav-justified" role="tablist">

                                                    {SelectedProductCodes.map((product, index) => (

                                                        <li key={product.value} class="nav-item ">
                                                            <a class={`nav-link ${index == 0 && 'active'}`} onClick={() => { handleTabChange(product.value) }} data-toggle="tab" href={`#tab${product.value}`} role="tab">{product.label.toString().substring(0,18)}</a>
                                                        </li>

                                                    ))}

                                                </ul>

                                                <div class="tab-content">
                                                    {SelectedProductCodes.map((product, index) => (

                                                        <div key={product.value} className={`tab-pane fade ${index == 0 && 'active show'}`} id={`tab${product.value}`}>

                                                            {
                                                                tabLoader.includes(product.value) ?

                                                                    <>
                                                                        <h3 className="fs-16 fw-500">{getTabreport(product.value).title}</h3>

                                                                        {getTabreport(product.value).data.length > 0 ?
                                                                         <DynamicDTFloatTabs
                                                                            tableData={getTabreport(product.value)}
                                                                            tableID={`tabledt_${product.value}`}
                                                                            firstColWidth="220px"
                                                                            secColWidth="90px"
                                                                            fixedColumns={true}
                                                                            handleExportSheet={handleExportMultiSheet}
                                                                        /> : <p className="alert alert-secondary" role="alert">No data for this product.</p>}

                                                                    </>
                                                                    : <BrandedLoader />
                                                            }


                                                        </div>
                                                    ))}


                                                </div>

                                            </div>
                                            <div className="card-footer">


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card report-card" style={{width: "auto" }}> 
                                            <div className="card-title report-card-title">{reportGenData.title}
                                                <button className="btn btn-outline btn-md  mr-10 fw-500" onClick={(e) => { window.location.reload() }}>BACK</button>
                                            </div>
                                            <div className="card-body">
                                                <DynamicDTFloat
                                                    tableData={reportGenData}
                                                    handleExportSheet={handleExportSheet}
                                                    firstColWidth="200px"
                                                    secColWidth="100px"
                                                    highlightedDataCells={["mth_fs","cum_fs"]}
                                                    fixedColumns={true}
                                                />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
                {MultiSheetDownloadStart && <MultiSheetProcessingLoader title="Please wait while we download the excel file." loadingText={sheetloaderMessage} />}

            </div>
        </>
    );


}

export default NW06;